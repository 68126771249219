import classNames from "classnames";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useCollections from "../../data-store/useCollections";
import { useTracking } from "../../tracking";
import { CollectionToSelect } from "../recipes/RecipeQueryControls.graphql";
import useRecipeLabel from "../recipes/useRecipeLabel";
import StatusDisplay from "../StatusDisplay";
import ManageTagsButton from "../tags/ManageTagsButton";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import Checkbox from "../utils/Checkbox";
import SearchBox from "../utils/SearchBox";
import Select from "../utils/Select";
import {
  Filter as FilterVector,
  Close as CloseVector,
  FilledCircle,
} from "../utils/Vectors";
import "./ProductFilterSelect.css";
import AttentionTriangle from "../utils/Vectors/AttentionTriangle";
import { ProductFilterSelect_Collection } from "./ProductFilterSelect.graphql";

interface IntersectCollectionsOption {
  display: string;
  type: "all" | "any";
}

interface ProductFilterMenuListProps {
  setFilters: (
    filterToSelectedCollectionIds: Array<number>,
    filterToRequiresClientAttention: boolean,
    intersectCollections: boolean
  ) => void;
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  onMenuClose: () => void;
  needsAttention: boolean;
  setNeedsAttention: (needsAttention: boolean) => void;
  selectedCollectionIds: Array<number>;
  setSelectedCollectionIds: (selectedCollectionIds: Array<number>) => void;
  intersectCollections: boolean;
  setIntersectCollections: (intersectCollections: boolean) => void;
}

const ProductFilterMenuList = (props: ProductFilterMenuListProps) => {
  const {
    setFilters,
    showGeneralStatusSection,
    showManageTagsButton,
    onMenuClose,
    needsAttention,
    setNeedsAttention,
    selectedCollectionIds,
    setSelectedCollectionIds,
    intersectCollections,
    setIntersectCollections,
  } = props;

  const [hoverRow, setHoverRow] = useState<
    CollectionToSelect | "Needs Attention" | null
  >(null);
  const recipeLabel = useRecipeLabel();

  const { trackFilterTagsApplied } = useTracking();

  const onApplyClick = (allCollections: ProductFilterSelect_Collection[]) => {
    // if a collection was deleted after it was selected, this makes sure we don't filter by it
    const notDeletedCollections = selectedCollectionIds.filter(
      (selectedCollection) =>
        allCollections.find(
          (collection) => collection.id === selectedCollection
        )
    );
    trackFilterTagsApplied({
      needsAttention,
      numberOfCollections: notDeletedCollections?.length ?? 0,
      intersectCollections,
    });
    setFilters(
      notDeletedCollections && notDeletedCollections.length > 0
        ? notDeletedCollections
        : [],
      needsAttention,
      intersectCollections
    );
    onMenuClose();
  };

  const [search, setSearch] = useState("");

  const intl = useIntl();

  const allCollectionIntersectionOption: IntersectCollectionsOption = {
    display: intl.formatMessage({
      id: "components/product-filter/ProductFilterSelect:all",
      defaultMessage: "all",
    }),
    type: "all",
  };
  const anyCollectionIntersectionOption: IntersectCollectionsOption = {
    display: intl.formatMessage({
      id: "components/product-filter/ProductFilterSelect:any",
      defaultMessage: "any",
    }),
    type: "any",
  };

  const [collectionsStatus] = useCollections();

  const handleCollectionCheckboxChange = (collectionId: number) => {
    // TODO: make sure this is in sync! just alternating scares me (same for needsAttention)
    if (selectedCollectionIds?.includes(collectionId)) {
      setSelectedCollectionIds(
        selectedCollectionIds.filter((id) => id !== collectionId)
      );
    } else {
      setSelectedCollectionIds([
        ...(selectedCollectionIds || []),
        collectionId,
      ]);
    }
    setSearch("");
  };

  const onClearFiltersClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedCollectionIds([]);
    setNeedsAttention(false);
    e.currentTarget.blur();
  };

  return (
    <div className="ProductFilterMenuList text-body react-select__menu">
      <div className="Header">
        <h4 className="Title">
          <FormattedMessage
            id="components/product-filter/ProductFilterSelect:Title"
            defaultMessage="Filter by"
          />
        </h4>
        <button onClick={onMenuClose} className="CloseButton">
          <CloseVector width={20} />
        </button>
      </div>
      {showGeneralStatusSection && (
        <>
          <hr className="Divider" />

          <div className="GeneralStatusSection">
            <div className="medium-font">
              <FormattedMessage
                id="components/product-filter/ProductFilterSelect:GeneralStatusHeading"
                defaultMessage="General status"
              />
            </div>
            <div
              className="ListItem"
              onClick={() => setNeedsAttention(!needsAttention)}
              onMouseOver={
                setHoverRow ? () => setHoverRow("Needs Attention") : undefined
              }
              onMouseLeave={setHoverRow ? () => setHoverRow(null) : undefined}
            >
              <Checkbox
                checked={needsAttention}
                hover={hoverRow === "Needs Attention"}
                label={
                  <div className="d-flex flex-row">
                    <AttentionTriangle className="my-auto mr-2" width={20} />
                    <FormattedMessage
                      id="components/product-filter/ProductFilterSelect:filterToRequiresClientAttention"
                      defaultMessage="Needs attention"
                    />
                  </div>
                }
                onChange={() => {
                  setNeedsAttention(!needsAttention);
                }}
                propagateOnClick={false}
              />
            </div>
          </div>
        </>
      )}

      <hr className="Divider" />

      <div className="CollectionsTableHeader medium-font d-flex">
        <FormattedMessage
          id="components/product-filter/ProductFilterSelect:TagHeading"
          defaultMessage="Tag"
        />
        {showManageTagsButton && (
          <span>
            <ManageTagsButton
              onCollectionDeleted={(deletedCollectionId) => {
                const withoutDeletedCollection = [
                  ...selectedCollectionIds,
                ].filter(
                  (filteredCollectionId) =>
                    filteredCollectionId !== deletedCollectionId
                );
                if (
                  withoutDeletedCollection.length !==
                  selectedCollectionIds.length
                ) {
                  setFilters(
                    withoutDeletedCollection,
                    needsAttention,
                    intersectCollections
                  );
                }
              }}
              withText={false}
            />
          </span>
        )}
      </div>
      <div className="any-or-all-row">
        <FormattedMessage
          id="components/product-filter/ProductFilterMenuList:recipeLabelsWith"
          defaultMessage="{recipeLabel} with"
          values={{ recipeLabel: recipeLabel.pluralUppercase }}
        />
        <span style={{ display: "inline-block", width: "72px" }}>
          <Select
            options={[
              anyCollectionIntersectionOption,
              allCollectionIntersectionOption,
            ]}
            onChange={(option) =>
              option ? setIntersectCollections(option.type === "all") : null
            }
            value={
              intersectCollections
                ? allCollectionIntersectionOption
                : anyCollectionIntersectionOption
            }
            optionKey={(x) => x.type}
            renderOption={(x) => x.display}
            isClearable={false}
            dropdownArrow={"upDown"}
          ></Select>
        </span>
        <FormattedMessage
          id="components/product-filter/ProductFilterMenuList:ofTheFollowingTags"
          defaultMessage="of the following tags:"
        />
      </div>
      <div className="SearchBox">
        <SearchBox
          onChange={setSearch}
          placeholder={intl.formatMessage({
            id: "components/product-filter/ProductFilterMenuList:searchBoxPlaceholder",
            defaultMessage: "Search tags",
          })}
          value={search}
          autoFocus
        />
      </div>
      <StatusDisplay status={collectionsStatus}>
        {(collections) => (
          <>
            <div className="CollectionsTableContainer">
              <div className="CollectionsTable">
                {collections
                  .filter((collection) =>
                    collection.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((collection) => (
                    <div
                      className={
                        hoverRow !== "Needs Attention" &&
                        collection.id === hoverRow?.id
                          ? "ListItem__RowHighlighted"
                          : "ListItem"
                      }
                      key={collection.id}
                      onMouseOver={
                        setHoverRow ? () => setHoverRow(collection) : undefined
                      }
                      onMouseLeave={
                        setHoverRow ? () => setHoverRow(null) : undefined
                      }
                      onClick={() =>
                        handleCollectionCheckboxChange(collection.id)
                      }
                    >
                      <Checkbox
                        containerClassName="Checkbox"
                        checked={selectedCollectionIds?.includes(collection.id)}
                        label={
                          <div className="d-flex flex-row">
                            <FormattedMessage
                              id="components/product-filter/ProductFilterSelect:collectionName"
                              defaultMessage="{collectionName}"
                              values={{ collectionName: collection.name }}
                            />
                          </div>
                        }
                        hover={
                          hoverRow !== "Needs Attention" &&
                          collection.id === hoverRow?.id
                        }
                        propagateOnClick={false}
                        onChange={() =>
                          handleCollectionCheckboxChange(collection.id)
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>

            <hr className="Divider" />

            <div className="EndButtons">
              <PrimaryButton onClick={() => onApplyClick(collections)}>
                {" "}
                <FormattedMessage
                  id="components/product-filter/ProductFilterSelect:ApplyButtonText"
                  defaultMessage="Apply"
                />
              </PrimaryButton>
              <SecondaryButton onClick={onClearFiltersClick}>
                <FormattedMessage
                  id="components/product-filter/ProductFilterSelect:ClearFiltersButtonText"
                  defaultMessage="Clear filters"
                />
              </SecondaryButton>
            </div>
          </>
        )}
      </StatusDisplay>
    </div>
  );
};

interface ProductFilterSelectProps {
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  setFilters: (
    filterToSelectedCollectionIds: Array<number>,
    filterToRequiresClientAttention: boolean,
    intersectCollections: boolean
  ) => void;
  filters?: {
    filterToSelectedCollectionIds: Array<number>;
    filterToRequiresClientAttention: boolean;
    intersectCollections: boolean;
  };
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
}

const filtersToNumberOfFilters = (filters: {
  filterToSelectedCollectionIds: Array<number>;
  filterToRequiresClientAttention: boolean;
}) => {
  // TODO: tidy when filterToSelectedCollectionIds isn't nullable
  let numberOfFilters = 0;
  numberOfFilters += filters.filterToSelectedCollectionIds.length;
  if (filters.filterToRequiresClientAttention) {
    numberOfFilters += 1;
  }
  return numberOfFilters;
};

export default function ProductFilterSelect(props: ProductFilterSelectProps) {
  const {
    showGeneralStatusSection,
    showManageTagsButton,
    setFilters,
    filters,
    menuIsOpen,
    setMenuIsOpen,
  } = props;

  const [currentNumberOfFilters, setCurrentNumberOfFilters] = useState(
    filters ? filtersToNumberOfFilters(filters) : 0
  );

  useEffect(() => {
    setCurrentNumberOfFilters(filters ? filtersToNumberOfFilters(filters) : 0);
  }, [filters]);

  const [needsAttention, setNeedsAttention] = useState(
    filters?.filterToRequiresClientAttention || false
  );
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<
    Array<number>
  >(filters?.filterToSelectedCollectionIds ?? []);

  const [intersectCollections, setIntersectCollections] = useState(
    filters?.intersectCollections || false
  );

  const productFilterSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        productFilterSelectRef.current &&
        !productFilterSelectRef.current.contains(event.target as Node) &&
        // stop modals triggered by ManageTagsButton from hiding when clicked
        // ActionModal adds this class to body when it's in use
        // https://getbootstrap.com/docs/4.0/components/modal/#usage
        // > "it also adds .modal-open to the <body>"
        !document.body.classList.contains("modal-open")
      ) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  const removeAppliedFiltersRef = React.createRef<HTMLDivElement>();

  const { trackFilterTagsStarted } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    if (
      removeAppliedFiltersRef.current == null ||
      (removeAppliedFiltersRef.current &&
        !removeAppliedFiltersRef.current.contains(e.target as Node))
      // todo: we should store filters and selected options together with some kind of reset state
      // for easier to read code
    ) {
      if (!menuIsOpen) {
        trackFilterTagsStarted();
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  };

  const onRemoveAppliedFiltersClick = () => {
    setSelectedCollectionIds([]);
    setNeedsAttention(false);
    setFilters([], false, filters ? filters.intersectCollections : false);
  };

  return (
    <div ref={productFilterSelectRef} className="react-select-container">
      {menuIsOpen && (
        <ProductFilterMenuList
          showGeneralStatusSection={showGeneralStatusSection}
          showManageTagsButton={showManageTagsButton}
          setFilters={setFilters}
          onMenuClose={() => setMenuIsOpen(false)}
          needsAttention={needsAttention}
          setNeedsAttention={setNeedsAttention}
          selectedCollectionIds={selectedCollectionIds}
          setSelectedCollectionIds={setSelectedCollectionIds}
          intersectCollections={intersectCollections}
          setIntersectCollections={setIntersectCollections}
        />
      )}
      <SecondaryButton
        className={classNames("ProductFilterButton", {
          ProductFilterButton__MenuOpen: menuIsOpen,
          ProductFilterButton__Filtered: currentNumberOfFilters > 0,
        })}
        onClick={(e) => handleClick(e)}
      >
        <FilterVector width={20} className="filter-icon" />
        <FormattedMessage
          id="components/product-filter/ProductFilterSelect:FilterButton"
          defaultMessage="Filter"
        />
        {currentNumberOfFilters > 0 && (
          <>
            <FilledCircle
              width="24px"
              fill="var(--accent-yellow)"
              className="number-of-filters"
              textColor="black"
              content={currentNumberOfFilters}
            />
            <div
              ref={removeAppliedFiltersRef}
              onClick={onRemoveAppliedFiltersClick}
              className="CloseButton"
            >
              <CloseVector width={16} />
            </div>
          </>
        )}
      </SecondaryButton>
    </div>
  );
}

ProductFilterSelect.fragments = {
  collection: gql`
    fragment ProductFilterSelect_Collection on RecipeCollection {
      id
      name
    }
  `,
};
