import { gql } from "graphql-tag";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import { ImpactCalculatorErrorKind } from "../../__generated__/globalTypes";
import useAvailableIngredients from "../../data-store/useAvailableIngredients";
import useCollections from "../../data-store/useCollections";
import { UseCollections_Collection } from "../../data-store/useCollections.graphql";
import AvailableIngredient from "../../domain/AvailableIngredient";
import { useTags } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import * as statuses from "../../util/statuses";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import usePagedQuery, {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../graphql/usePagedQuery";
import useQuery from "../graphql/useQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import BackButton from "../utils/BackButton";
import Card from "../utils/Card";
import {
  AddRecipe,
  AddRecipeVariables,
  CookingImpactsQuery,
  PackagingComponentsQuery,
  RecipeCard_PackagingComponentV2 as PackagingComponentV2,
  RecipeCard_Recipe as Recipe,
  RecipeQuery,
  RecipeQueryVariables,
  StorageMethodsQuery,
  UpdateRecipe,
  UpdateRecipeVariables,
  PackagingComponentsQueryVariables,
} from "./RecipeCard.graphql";
import RecipeEditor, { RecipeInput } from "./RecipeEditor/RecipeEditor";

type CookingImpact = CookingImpactsQuery["cookingImpacts"]["edges"][0]["node"];
type StorageMethod = StorageMethodsQuery["storageMethods"]["edges"][0]["node"];

interface RecipeCardLoaderProps {
  onAfterSave: (recipeId: number) => void;
  onDiscard: () => void;
  recipeId?: number;
  refresh?: () => Promise<void>;
}

const addRecipeMutation = gql`
  mutation AddRecipe($input: AddRecipeInput!) {
    addRecipe(input: $input) {
      recipe {
        id
      }
    }
  }
`;

const updateRecipeMutation = gql`
  mutation UpdateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      recipe {
        impact(excludePackaging: false, fetchStaleImpacts: false) {
          errors {
            kind
            originalError
          }
        }
      }
      success
    }
  }
`;

export default function RecipeCardLoader(props: RecipeCardLoaderProps) {
  const { onAfterSave, onDiscard, recipeId, refresh } = props;

  if (recipeId === undefined) {
    return (
      <RecipeCard
        onAfterSave={onAfterSave}
        onDiscard={onDiscard}
        recipe={undefined}
        refresh={refresh}
      />
    );
  } else {
    return (
      <ExistingRecipeCardLoader
        onAfterSave={onAfterSave}
        onDiscard={onDiscard}
        recipeId={recipeId}
        refresh={refresh}
      />
    );
  }
}

interface ExistingRecipeCardLoaderProps {
  onAfterSave: (recipeId: number) => void;
  onDiscard: () => void;
  recipeId: number;
  refresh?: () => Promise<void>;
}

function ExistingRecipeCardLoader(props: ExistingRecipeCardLoaderProps) {
  const { onAfterSave, onDiscard, recipeId, refresh } = props;

  const { status: recipeDataStatus, refresh: refreshRecipe } = useQuery<
    RecipeQuery,
    RecipeQueryVariables
  >(recipeQuery, { recipeId });

  async function refreshAll(): Promise<void> {
    await Promise.all([
      refresh === undefined ? null : refresh(),
      refreshRecipe(),
    ]);
  }

  return (
    <StatusDisplay status={recipeDataStatus}>
      {({ recipe }) =>
        recipe === null ? (
          <p>
            <FormattedMessage
              id="components/recipes/RecipeCard:recipeNotFoundMessage"
              defaultMessage="Recipe not found."
            />
          </p>
        ) : (
          <RecipeCard
            onAfterSave={onAfterSave}
            onDiscard={onDiscard}
            recipe={recipe}
            refresh={refreshAll}
          />
        )
      }
    </StatusDisplay>
  );
}

interface RecipeCardProps {
  onAfterSave: (recipeId: number) => void;
  onDiscard: () => void;
  recipe?: Recipe;
  refresh?: () => Promise<void>;
}

function RecipeCard(props: RecipeCardProps) {
  const { onAfterSave, onDiscard, recipe, refresh } = props;

  const { trackRecipeEditSubmitted } = useTracking();

  const [addRecipe] = useMutation<AddRecipe, AddRecipeVariables>(
    addRecipeMutation
  );
  const [updateRecipe] = useMutation<UpdateRecipe, UpdateRecipeVariables>(
    updateRecipeMutation
  );

  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();

  async function handleSave(input: RecipeInput): Promise<void> {
    let recipeId: number;
    if (recipe === undefined) {
      const response = await addRecipe({
        variables: { input: { ownerOrganizationId: organizationId, ...input } },
      });
      recipeId = response.addRecipe.recipe.id;
    } else {
      const response = await updateRecipe({
        variables: {
          input: {
            id: recipe.id,
            ownerOrganizationId: recipe.ownerOrganizationId,
            ...input,
          },
        },
      });
      const errors = response.updateRecipe.recipe.impact.errors;
      const circularErrors = errors.filter(
        (e) =>
          e.kind === ImpactCalculatorErrorKind.RECIPE_HAS_CIRCULAR_REFERENCE
      );
      if (circularErrors.length) {
        throw new UserVisibleError(circularErrors[0].originalError);
      }
      recipeId = recipe.id;
    }

    trackRecipeEditSubmitted({
      newRecipe: recipe === undefined,
      recipeId,
      recipeName: input.name,
      simpleIngredientCount: input.ingredients.filter(
        (ingredient) => ingredient.useRecipeId == null
      ).length,
      subrecipeIngredientCount: input.ingredients.filter(
        (ingredient) => ingredient.useRecipeId != null
      ).length,
    });

    // We have a bug where the page doesn't update after saving, so for now we just refresh the page.
    // Using history.replace instead of location.reload to make it slightly less jarring.
    // The two replaces together act like a reload.  A single .replace(currentLocation) does nothing.
    // I think the reason just .push() is insuficient, and the replaces are necessary, is because
    // the push does not change the path, it just changes the "search" part of the url.
    const currentLocation = history.location;
    history.replace("/");
    history.replace(currentLocation);

    // Moved this here (it was before trackRecipeEditSubmitted()) to make the page not reload twice.
    history.push({
      pathname: pages.Recipe(hasFeatureTags).url({
        id: recipeId,
      }),
      search: "state=view",
    });
    if (refresh) {
      await refresh();
    }

    onAfterSave(recipeId);
  }

  const [collectionsStatus, refreshCollections] = useCollections();
  const cookingImpactsStatus = useCookingImpacts();
  const [organizationId] = useOrganizationId();
  const [availableIngredientsStatus] = useAvailableIngredients(organizationId);
  const packagingComponentsStatus = usePackagingComponents(organizationId);
  const storageMethodsStatus = useStorageMethods();

  return (
    <div className="d-flex justify-content-center flex-row">
      <Card className="d-flex flex-column mw-100 m-4 p-4">
        <div className="d-flex align-items-center mb-4">
          <BackButton back={() => onDiscard()} />
          <h2 className="my-0 ml-3">
            {recipe !== undefined ? (
              <FormattedMessage
                id="components/recipes/RecipeCard:editRecipeTitle"
                defaultMessage="Edit {recipeTitle}"
                values={{ recipeTitle: recipe.name }}
              />
            ) : (
              <FormattedMessage
                id="components/recipes/RecipeCard:createNewProductTitle"
                defaultMessage="Create a new product"
              />
            )}
          </h2>
        </div>

        <StatusDisplay.Many<
          [
            Array<AvailableIngredient>,
            Array<UseCollections_Collection>,
            Array<CookingImpact>,
            Array<StorageMethod>,
            Array<PackagingComponentV2>
          ]
        >
          statuses={[
            availableIngredientsStatus,
            collectionsStatus,
            cookingImpactsStatus,
            storageMethodsStatus,
            packagingComponentsStatus,
          ]}
        >
          {(
            availableIngredients,
            collections,
            cookingImpacts,
            storageMethods,
            packagingComponents
          ) => (
            <RecipeEditor
              availableIngredients={availableIngredients}
              cookingImpacts={cookingImpacts}
              packagingComponents={packagingComponents}
              onDiscard={() => onDiscard()}
              onSave={handleSave}
              possibleCollections={collections}
              recipe={recipe}
              refreshPossibleCollections={refreshCollections}
              storageMethods={storageMethods}
            />
          )}
        </StatusDisplay.Many>
      </Card>
    </div>
  );
}

function useCookingImpacts() {
  const { status } = usePagedQueryFetchAll<
    CookingImpactsQuery,
    {},
    CookingImpact
  >(cookingImpactsQuery, {}, (data) => data.cookingImpacts);

  return statuses.map(status, extractNodesFromPagedQueryResult);
}

const cookingImpactsQuery = gql`
  query CookingImpactsQuery {
    cookingImpacts(first: 1000) {
      edges {
        node {
          ...RecipeEditor_CookingImpact
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${RecipeEditor.fragments.cookingImpact}
`;

function useStorageMethods() {
  const { status, fetchNextPage } = usePagedQuery<
    StorageMethodsQuery,
    {},
    StorageMethod
  >(storageMethodsQuery, {}, (data) => data.storageMethods);

  if (fetchNextPage !== null) {
    throw new UserVisibleError("Failed to fetch all storage methods");
  }

  return statuses.map(status, extractNodesFromPagedQueryResult);
}

const storageMethodsQuery = gql`
  query StorageMethodsQuery {
    storageMethods(first: 1000) {
      edges {
        node {
          ...RecipeEditor_StorageMethod
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${RecipeEditor.fragments.storageMethod}
`;

const recipeQuery = gql`
  query RecipeQuery($recipeId: Int!) {
    recipe(filter: { ids: [$recipeId] }) {
      ...RecipeCard_Recipe
    }
  }

  fragment RecipeCard_Recipe on Recipe {
    id
    ownerOrganizationId
    ...RecipeEditor_Recipe
  }

  ${RecipeEditor.fragments.recipe}
`;

function usePackagingComponents(organizationId: string) {
  const { status } = usePagedQueryFetchAll<
    PackagingComponentsQuery,
    PackagingComponentsQueryVariables,
    PackagingComponentV2
  >(
    packagingComponentsQuery,
    { organizationId },
    (data) => data.packagingComponents
  );

  return statuses.map(status, extractNodesFromPagedQueryResult);
}

const packagingComponentsQuery = gql`
  query PackagingComponentsQuery($organizationId: UUID!, $after: String) {
    packagingComponents(
      first: 1000
      after: $after
      filter: {
        anyOf: [
          { organizationId: $organizationId }
          { isStandardComponent: true }
        ]
      }
    ) {
      edges {
        node {
          ...RecipeCard_PackagingComponentV2
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment RecipeCard_PackagingComponentV2 on PackagingComponentV2 {
    ...RecipeEditor_PackagingComponentV2
  }

  ${RecipeEditor.fragments.packagingComponent}
`;
