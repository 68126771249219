import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import isString from "lodash/isString";

let monitoringInited = false;

export function init() {
  const dsn = sentryDsn();

  if (dsn !== null) {
    try {
      Sentry.init({
        dsn,
        environment: (window as any).FOODSTEPS_ENVIRONMENT,
        integrations: [new Integrations.BrowserTracing()],
        maxBreadcrumbs: 0,
        tracesSampleRate: 0,
      });
      monitoringInited = true;
    } catch (error) {
      console.error(error);
    }
  }
}

export function reportError(error: unknown) {
  if (monitoringInited) {
    Sentry.captureException(error);
  }
}

function sentryDsn(): string | null {
  const dsn: unknown = (window as any).FOODSTEPS_SENTRY_DSN;
  if (isString(dsn) && dsn.length > 0) {
    return dsn;
  } else {
    return null;
  }
}
