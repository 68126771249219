import gql from "graphql-tag";
import { createContext, useContext } from "react";

import useMutation, {
  MutationOptions,
} from "../components/graphql/useMutation";
import {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../components/graphql/usePagedQuery";
import { useOrganizationOrNull } from "../components/organizations/OrganizationProvider";
import { IntoCollectionsModal } from "../components/recipes/CopyToButton";
import * as ProductEditor from "../components/recipes/ProductEditor/ProductEditor";
import RecipeEditor from "../components/recipes/RecipeEditor/RecipeEditor";
import AddTagModal from "../components/tags/AddTagModal";
import ManageTagsButton from "../components/tags/ManageTagsButton";
import * as statuses from "../util/statuses";
import {
  UseCollections_AddCollection,
  UseCollections_AddCollectionVariables,
  UseCollections_Collection,
  UseCollections_CollectionsQuery,
  UseCollections_CollectionsQueryVariables,
  UseCollections_DeleteCollection,
  UseCollections_DeleteCollectionVariables,
  UseCollections_UpdateCollection,
  UseCollections_UpdateCollectionVariables,
} from "./useCollections.graphql";

const CollectionsContext = createContext<
  | [
      statuses.Status<UseCollections_Collection[]>,
      () => Promise<void>,
      (
        options: MutationOptions<UseCollections_AddCollectionVariables>
      ) => Promise<UseCollections_AddCollection>,
      (
        options: MutationOptions<UseCollections_DeleteCollectionVariables>
      ) => Promise<UseCollections_DeleteCollection>,
      (
        options: MutationOptions<UseCollections_UpdateCollectionVariables>
      ) => Promise<UseCollections_UpdateCollection>
    ]
  | undefined
>(undefined);

interface CollectionsProviderProps {
  organizationId: string;
  children: React.ReactNode;
}

function CollectionsProvider(props: CollectionsProviderProps) {
  const { children, organizationId } = props;

  const { status, refresh } = usePagedQueryFetchAll<
    UseCollections_CollectionsQuery,
    UseCollections_CollectionsQueryVariables,
    UseCollections_Collection
  >(collectionsQuery(), { organizationId }, (data) => data.recipeCollections);

  const collectionsStatus = statuses.map(
    status,
    extractNodesFromPagedQueryResult
  );

  const [addRecipeCollection] = useMutation<
    UseCollections_AddCollection,
    UseCollections_AddCollectionVariables
  >(addCollectionMutation);

  const addCollectionAndRefresh = async (
    options: MutationOptions<UseCollections_AddCollectionVariables>
  ) => {
    const result = await addRecipeCollection(options);
    await refresh();
    return result;
  };

  const [deleteCollection] = useMutation<
    UseCollections_DeleteCollection,
    UseCollections_DeleteCollectionVariables
  >(deleteCollectionMutation);

  const deleteCollectionAndRefresh = async (
    options: MutationOptions<UseCollections_DeleteCollectionVariables>
  ) => {
    const result = await deleteCollection(options);
    await refresh();
    return result;
  };

  const [updateCollections] = useMutation<
    UseCollections_UpdateCollection,
    UseCollections_UpdateCollectionVariables
  >(updateCollectionMutation);

  const updateCollectionsAndRefresh = async (
    options: MutationOptions<UseCollections_UpdateCollectionVariables>
  ) => {
    const result = await updateCollections(options);
    await refresh();
    return result;
  };

  return (
    <CollectionsContext.Provider
      value={[
        collectionsStatus,
        refresh,
        addCollectionAndRefresh,
        deleteCollectionAndRefresh,
        updateCollectionsAndRefresh,
      ]}
    >
      {children}
    </CollectionsContext.Provider>
  );
}

export function CollectionsProviderOwnOrganization(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  const [organization] = useOrganizationOrNull();

  if (organization === null) {
    return <>{children}</>;
  } else {
    return (
      <CollectionsProvider organizationId={organization.id}>
        {children}
      </CollectionsProvider>
    );
  }
}

export function CollectionsProviderParentOrganization(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  const [organization] = useOrganizationOrNull();

  if (organization === null) {
    return <>{children}</>;
  } else if (organization.parentId === null) {
    throw new Error(
      "CollectionsProviderParentOrganization cannot be used when organization has no parent"
    );
  } else {
    return (
      <CollectionsProvider organizationId={organization.parentId}>
        {children}
      </CollectionsProvider>
    );
  }
}

export default function useCollections(): [
  statuses.Status<UseCollections_Collection[]>,
  () => Promise<void>,
  (
    options: MutationOptions<UseCollections_AddCollectionVariables>
  ) => Promise<UseCollections_AddCollection>,
  (
    options: MutationOptions<UseCollections_DeleteCollectionVariables>
  ) => Promise<UseCollections_DeleteCollection>,
  (
    options: MutationOptions<UseCollections_UpdateCollectionVariables>
  ) => Promise<UseCollections_UpdateCollection>
] {
  const result = useContext(CollectionsContext);

  if (result === undefined) {
    throw new Error("CollectionsProvider not present in component tree");
  } else {
    return result;
  }
}

const collectionsQuery = () => gql`
  query UseCollections_CollectionsQuery(
    $after: String
    $organizationId: UUID!
  ) {
    recipeCollections(
      after: $after
      first: 1000
      filter: { ownerOrganizations: { id: $organizationId } }
    ) {
      edges {
        node {
          ...UseCollections_Collection
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment UseCollections_Collection on RecipeCollection {
    recipeCount
    ...RecipeEditor_Collection
    ...CopyToButton_Collection
    ...ManageTagsButton_Collection
    ...ProductEditor_Collection
  }

  ${RecipeEditor.fragments.collection}
  ${IntoCollectionsModal.fragments.collection}
  ${ManageTagsButton.fragments.collection}
  ${ProductEditor.collectionFragment()}
`;

const addCollectionMutation = gql`
  mutation UseCollections_AddCollection($input: AddRecipeCollectionInput!) {
    addRecipeCollection(input: $input) {
      recipeCollection {
        id
        name
        ...AddTagModal_RecipeCollection
      }
      success
    }
  }

  ${AddTagModal.fragments.recipeCollection()}
`;

const deleteCollectionMutation = gql`
  mutation UseCollections_DeleteCollection($collectionId: Int!) {
    deleteRecipeCollection(input: { id: $collectionId }) {
      success
    }
  }
`;

const updateCollectionMutation = gql`
  mutation UseCollections_UpdateCollection(
    $input: UpdateRecipeCollectionInput!
  ) {
    updateRecipeCollection(input: $input) {
      success
    }
  }
`;
