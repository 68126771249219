import { EffectType } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import {
  CarbonLabelSizeOption,
  CarbonLabelType,
} from "./carbon-label/CarbonLabel";

export type CarbonLabelDisplayedSizeOption =
  | "Original"
  | "Medium"
  | "Large"
  | "Extra Large";

export type CarbonLabelDisplayedType =
  | "Descriptive Impact & Scale"
  | "Descriptive Rating"
  | "Descriptive Rating Scale"
  | "Letter Rating"
  | "Rating Scale"
  | "Titled Rating Scale";

export const impactMagnitude = (
  isHotDrink: boolean,
  effects: {
    ghgPerRootRecipeServing: number | null;
    ghgPerKg: number | null;
  } | null
): number => {
  return isHotDrink ? effects!.ghgPerRootRecipeServing! : effects!.ghgPerKg!;
};

export const effectType = (isHotDrink: boolean): EffectType => {
  return isHotDrink ? EffectType.GHG_PER_SERVING : EffectType.GHG_PER_KG;
};

export function labelSizeToDisplayedSize(
  labelSize: CarbonLabelSizeOption
): CarbonLabelDisplayedSizeOption {
  if (labelSize === "small") {
    return "Original";
  } else if (labelSize === "medium") {
    return "Medium";
  } else if (labelSize === "large") {
    return "Large";
  } else if (labelSize === "x-large") {
    return "Extra Large";
  } else {
    assertNever(labelSize, "Unsupported CarbonLabelSizeOption");
  }
}

export function labelTypeToDisplayedType(
  labelType: CarbonLabelType
): CarbonLabelDisplayedType {
  if (labelType === "descriptiveImpactAndScale") {
    return "Descriptive Impact & Scale";
  } else if (labelType === "descriptiveRating") {
    return "Descriptive Rating";
  } else if (labelType === "descriptiveRatingScale") {
    return "Descriptive Rating Scale";
  } else if (labelType === "letterRating") {
    return "Letter Rating";
  } else if (labelType === "ratingScale") {
    return "Rating Scale";
  } else if (labelType === "titledRatingScale") {
    return "Titled Rating Scale";
  } else {
    assertNever(labelType, "Unsupported CarbonLabelType");
  }
}
