import {
  highD,
  lowB,
  mediumC,
  noRating,
  veryHighE,
  veryLowA,
} from "../components/graphs/colors";
import * as comparators from "../util/comparators";

export type ImpactRating = "VLOW" | "LOW" | "MEDIUM" | "HIGH" | "VHIGH";

export const allImpactRatings: ReadonlyArray<ImpactRating> = [
  "VLOW",
  "LOW",
  "MEDIUM",
  "HIGH",
  "VHIGH",
];

export const comparator: comparators.Comparator<ImpactRating> = comparators.map(
  (rating) => allImpactRatings.indexOf(rating),
  comparators.number
);

export function isImpactRating(value: string): value is ImpactRating {
  const allImpactRatingsAsStrings: ReadonlyArray<string> = allImpactRatings;
  return allImpactRatingsAsStrings.includes(value);
}

export function readImpactRating(value: string | null): ImpactRating | null {
  if (value === null) {
    return null;
  } else if (isImpactRating(value)) {
    return value;
  } else {
    return null;
  }
}

export function impactRatingToLongName(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "Very Low";
    case "LOW":
      return "Low";
    case "MEDIUM":
      return "Medium";
    case "HIGH":
      return "High";
    case "VHIGH":
      return "Very High";
  }
}

export function impactRatingToLetter(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "A";
    case "LOW":
      return "B";
    case "MEDIUM":
      return "C";
    case "HIGH":
      return "D";
    case "VHIGH":
      return "E";
  }
}

export function impactRatingToColor(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "var(--label-A)";
    case "LOW":
      return "var(--label-B)";
    case "MEDIUM":
      return "var(--label-C)";
    case "HIGH":
      return "var(--label-D)";
    case "VHIGH":
      return "var(--label-E)";
  }
}

export function impactRatingToColorRgb(
  impactRating: ImpactRating | null
): string {
  switch (impactRating) {
    case "VLOW":
      return veryLowA;
    case "LOW":
      return lowB;
    case "MEDIUM":
      return mediumC;
    case "HIGH":
      return highD;
    case "VHIGH":
      return veryHighE;
    case null:
      return noRating;
  }
}
