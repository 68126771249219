import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { IngredientInput } from "../../../__generated__/globalTypes";
import { ingredientQuantityUnits } from "../../../domain/units";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import TableEditor from "../../utils/TableEditor";
import {
  EditorIngredient,
  IngredientType,
  existingIngredient,
  readIngredient,
} from "../RecipeEditor/RecipeIngredientsEditor";
import { LossPercentageEditor } from "./LossPercentageEditor";
import { PhysicalInputEditor_Ingredient } from "./PhysicalInputEditor.graphql";
import "./PhysicalInputEditor.css";

export interface Value extends EditorIngredient {
  unitProcess: {
    processLoss: FloatInput.Value;
  };
}

export function initialValue(
  ingredient: PhysicalInputEditor_Ingredient
): Value {
  return {
    ...existingIngredient(ingredient),
    unitProcess: {
      processLoss: FloatInput.initialValue(
        ingredient.unitProcess?.processLoss
          ? ingredient.unitProcess.processLoss * 100
          : null
      ),
    },
  };
}

export function readPhysicalInput(
  value: Value
): ReadResult<Value, IngredientInput> {
  const ingredientResult = readIngredient(value);
  const processLossResult = FloatInput.readAllowEmpty({
    value: value.unitProcess.processLoss,
    validationCondition: (value) => value >= 0 && value < 100,
  });
  if (ingredientResult.hasError || processLossResult.hasError) {
    return {
      hasError: true,
      value: {
        ...ingredientResult.value,
        unitProcess: {
          processLoss: processLossResult.value,
        },
      },
    };
  } else {
    return {
      hasError: false,
      input: {
        ...ingredientResult.input,
        unitProcess: {
          processLoss: processLossResult.input
            ? processLossResult.input / 100
            : null,
        },
      },
      value: {
        ...ingredientResult.value,
        unitProcess: {
          processLoss: processLossResult.value,
        },
      },
    };
  }
}

interface PhysicalInputEditorProps {
  value: Value;
  onChange: (newState: Value) => void;
  onDelete?: () => void;
}

export const PHYSICAL_INPUT_EDITOR_DELETE_COLUMN_WIDTH = "20px";

export default function PhysicalInputEditor(props: PhysicalInputEditorProps) {
  const { value, onChange, onDelete } = props;

  return (
    <tr className="d-flex w-100 flex-row" style={{ alignItems: "center" }}>
      <td className="PhysicalInputEditor_NameContainer">
        {value.ingredientType === IngredientType.SIMPLE
          ? value.name
          : value.useRecipe?.name}
      </td>
      <td className="PhysicalInputEditor_QuantityContainer">
        <FloatInput.FloatInput
          onChange={(quantity) => onChange({ ...value, quantity })}
          value={value.quantity}
          className="w-50"
        />
        <select
          id="physicalInputEditorUnit"
          className="form-control w-50 ml-2 text-right"
          onChange={(event) => onChange({ ...value, unit: event.target.value })}
          value={value.unit}
        >
          {ingredientQuantityUnits({ isSubrecipe: false }).map((unit) => (
            <option key={unit.value} value={unit.value}>
              {unit.label}
            </option>
          ))}
        </select>
      </td>
      <td className="PhysicalInputEditor_LossPercentageContainer">
        <LossPercentageEditor
          value={value.unitProcess.processLoss}
          onChange={(processLoss) =>
            onChange({
              ...value,
              unitProcess: { ...value.unitProcess, processLoss },
            })
          }
        />
      </td>
      {onDelete ? (
        <TableEditor.DeleteCell
          onDelete={onDelete}
          width={PHYSICAL_INPUT_EDITOR_DELETE_COLUMN_WIDTH}
        />
      ) : (
        <td style={{ width: PHYSICAL_INPUT_EDITOR_DELETE_COLUMN_WIDTH }}></td>
      )}
    </tr>
  );
}

export function PhysicalInputsEditorHeader() {
  return (
    <div
      className="d-flex w-100 medium-font flex-row"
      style={{ alignItems: "center", paddingBottom: "0px" }}
    >
      <div className="PhysicalInputEditor_NameContainer">
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:TableHeader/ingredients"
          defaultMessage="Ingredients"
        />
      </div>
      <div className="PhysicalInputEditor_QuantityContainer">
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:TableHeader/quantity"
          defaultMessage="Quantity"
        />
      </div>
      <div className="PhysicalInputEditor_LossPercentageContainer">
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:TableHeader/percentageLost"
          defaultMessage="Percentage Lost"
        />
      </div>
      <div style={{ width: PHYSICAL_INPUT_EDITOR_DELETE_COLUMN_WIDTH }}></div>
    </div>
  );
}

export const fragments = {
  ingredient: gql`
    fragment PhysicalInputEditor_IngredientUnitProcess on RecipeIngredientUnitProcess {
      processLoss
    }

    fragment PhysicalInputEditor_Ingredient on RecipeIngredient {
      ...RecipeIngredientsEditor_RecipeIngredient
      unitProcess {
        ...PhysicalInputEditor_IngredientUnitProcess
      }
    }
  `,
};
