import * as graphql from "graphql";
import { useMemoOne } from "use-memo-one";

import { CalculationTableRow } from "../../components/calculation-table/CalculationTable";
import FetchGraphQL from "../../components/graphql/FetchGraphQL";
import {
  ChangePasswordOptions,
  DataStore,
  DataStoreProvider,
  MaintenanceWindow,
} from "../../data-store";
import AvailableIngredient from "../../domain/AvailableIngredient";
import { createUnauthenticatedHttpClient } from "../../services/api/Client";
import { useSession } from "../../sessions";
import { useGetAccessToken } from "../../sessions/ServerSessionProvider";
import UserVisibleError from "../../util/UserVisibleError";
import {
  createAuthenticatedHttpClient,
  HttpClient,
  HttpMethod,
  HttpResponse,
} from "./Client";

interface RemoteApiProviderProps {
  children: React.ReactNode;
}

export default function RemoteApiProvider(props: RemoteApiProviderProps) {
  const { children } = props;

  const httpClient = useAuthenticatedHttpClient();

  const dataStore = useMemoOne(
    () => createDataStore({ httpClient }),
    [httpClient]
  );

  return <DataStoreProvider value={dataStore}>{children}</DataStoreProvider>;
}

function useAuthenticatedHttpClient(): HttpClient {
  const getAccessToken = useGetAccessToken();
  const { impersonatedUser, userId } = useSession();
  const impersonatedUserId = impersonatedUser?.id ?? null;

  const client = useMemoOne(() => {
    const key =
      userId === null
        ? null
        : {
            userId,
            impersonatedUserId,
          };

    return createAuthenticatedHttpClient({
      getAccessToken,
      impersonatedUserId,
      key,
    });
  }, [getAccessToken, impersonatedUserId, userId]);

  return client;
}

function createDataStore({
  httpClient,
}: {
  httpClient: HttpClient;
}): DataStore {
  const fetchJson = async <T,>({
    method,
    path,
    body,
  }: {
    method: HttpMethod;
    path: string;
    body?: unknown;
  }): Promise<T> => {
    const response = await httpClient.request({
      body,
      method,
      path,
    });

    if ([200, 201].includes(response.status)) {
      return (await response.json()) as T;
    } else {
      throw new Error("unexpected status code: " + response.status);
    }
  };

  const getJson = async <T,>(path: string): Promise<T> =>
    fetchJson<T>({ method: "GET", path });

  const fetchGraphQL = createFetchGraphQL(httpClient);

  return {
    fetchGraphQL,

    fetchAvailableIngredients: async (
      organizationId: string | null = null
    ): Promise<Array<AvailableIngredient>> => {
      const url = new URL(
        "api/v1/available-ingredients/",
        window.location.origin
      );
      if (organizationId !== null) {
        url.searchParams.append("organizationId", organizationId);
      }

      return await getJson(url.pathname + url.search);
    },

    fetchCalculationTable: async (
      subjectId: string,
      subjectType: string,
      otherParams: URLSearchParams
    ): Promise<CalculationTableRow> => {
      const httpClient = createUnauthenticatedHttpClient();
      const response = await httpClient.request({
        method: "GET",
        path: `admin/calculation-table/?subjectId=${subjectId}&subjectType=${subjectType}&${otherParams.toString()}`,
      });
      const json = await response.json();
      const newCalculationTableRow = json.newCalculationTableRow;

      return {
        amount_kg: newCalculationTableRow.amount_kg,
        consumption_location_str:
          newCalculationTableRow.consumption_location_str,
        data_quality_score: newCalculationTableRow.data_quality_score,
        geographic_data_quality_score:
          newCalculationTableRow.geographic_data_quality_score,
        technology_data_quality_score:
          newCalculationTableRow.technology_data_quality_score,
        temporal_data_quality_score:
          newCalculationTableRow.temporal_data_quality_score,
        economic_allocation_str: newCalculationTableRow.economic_allocation_str,
        effects: newCalculationTableRow.effects,
        item_type: newCalculationTableRow.item_type,
        loss_str: newCalculationTableRow.loss_str,
        method: newCalculationTableRow.method,
        multiplier_str: newCalculationTableRow.multiplier_str,
        name: newCalculationTableRow.name,
        link: newCalculationTableRow.link,
        notes: newCalculationTableRow.notes,
        errors: newCalculationTableRow.errors,
        num_servings: newCalculationTableRow.num_servings,
        processing_location_str: newCalculationTableRow.processing_location_str,
        source_location_str: newCalculationTableRow.source_location_str,
        system_boundary_str: newCalculationTableRow.system_boundary_str,
        child_rows: newCalculationTableRow.child_rows,
      };
    },

    fetchMaintenanceWindows: async (): Promise<Array<MaintenanceWindow>> => {
      const httpClient = createUnauthenticatedHttpClient();
      const response = await httpClient.request({
        method: "GET",
        path: "api/v1/maintenance-windows/",
      });
      if (response.status !== 200) {
        throw new Error("unexpected status code: " + response.status);
      }

      interface MaintenanceWindowJson {
        start: string;
        end: string;
        isActive: boolean;
      }

      const body: Array<MaintenanceWindowJson> = await response.json();

      return body.map((window) => ({
        start: new Date(window.start),
        end: new Date(window.end),
        isActive: window.isActive,
      }));
    },

    fetchMethodologySummaryUrl: () => Promise.resolve(null),

    fetchPublicOrganizationData: async (
      organizationId: string
    ): Promise<{ organizationName: string }> => {
      const httpClient = createUnauthenticatedHttpClient();
      const response = await httpClient.request({
        method: "GET",
        path: `api/v1/organizations/${organizationId}`,
      });

      if (response.status !== 200) {
        throw new Error(
          `unexpected status code whilst fetching public organization data: ${response.status}`
        );
      }

      interface PublicOrganizationDataJson {
        name: string;
      }

      const body: PublicOrganizationDataJson = await response.json();

      return {
        organizationName: body.name,
      };
    },

    changePassword: async ({
      userId,
      username,
      oldPassword,
      newPassword,
    }: ChangePasswordOptions) => {
      const url = "api/v1/users/" + userId + "/change_password/";
      let response: HttpResponse = await httpClient.request({
        method: "POST",
        path: url,
        body: {
          username,
          old_password: oldPassword,
          new_password: newPassword,
        },
      });

      if (response.status === 204) {
        return;
      } else if (response.status === 403) {
        throw new UserVisibleError("Provided password was incorrect.");
      } else {
        throw new Error("unexpected status code: " + response.status);
      }
    },

    fetchSupportedRegions: async () => {
      const httpClient = createUnauthenticatedHttpClient();
      const response = await httpClient.request({
        method: "GET",
        path: "api/v1/supported-regions/",
      });

      if (response.status !== 200) {
        throw new Error("unexpected status code: " + response.status);
      }

      return await response.json();
    },

    key: httpClient.key,
  };
}

export function createFetchGraphQL(httpClient: HttpClient): FetchGraphQL {
  return async ({ query, variables }) => {
    const response = await httpClient.request({
      method: "POST",
      path: "api/v1/graphql",
      body: {
        query: graphql.print(query),
        variables,
      },
    });

    if (response.status !== 200) {
      throw new Error(
        "Response status was: " + response.status + " " + response.statusText
      );
    }

    const responseBody = await response.json();

    const isUserVisibleGraphQLError = (value: unknown): boolean => {
      const isGraphQLError = (
        value: unknown
      ): value is { message: string; isUserVisible: boolean } => {
        return (
          typeof value == "object" &&
          value !== null &&
          value.hasOwnProperty("isUserVisible") &&
          value.hasOwnProperty("message")
        );
      };

      return isGraphQLError(value) && value.isUserVisible;
    };

    if (responseBody.errors && responseBody.errors.length > 0) {
      const error = responseBody.errors[0];
      if (isUserVisibleGraphQLError(error)) {
        throw new UserVisibleError(error.message);
      }

      throw new Error("GraphQL error: " + responseBody.errors[0].message);
    }

    return responseBody.data;
  };
}
