import * as ColourLetterRatingLabels from "../../utils/Vectors/carbon-labels/colour/letter-rating";
import * as MonochromeLetterRatingLabels from "../../utils/Vectors/carbon-labels/monochrome/letter-rating";
import {
  BaseCarbonLabelProps,
  CarbonLabelSizeOption,
  labelSizeToDimensions,
} from "./CarbonLabel";

export interface LetterRatingLabelProps extends BaseCarbonLabelProps {
  size: CarbonLabelSizeOption;
}

export default function LetterRatingLabel(props: LetterRatingLabelProps) {
  const { className, impactRating, monochrome, size, width } = props;
  const LetterRatingLabels = monochrome
    ? MonochromeLetterRatingLabels
    : ColourLetterRatingLabels;

  const labelDimensions = labelSizeToDimensions("letterRating", size);
  const labelWidth = width ?? labelDimensions.width;
  switch (impactRating) {
    case "VLOW":
      return (
        <LetterRatingLabels.LetterRatingLabelVLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "LOW":
      return (
        <LetterRatingLabels.LetterRatingLabelLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "MEDIUM":
      return (
        <LetterRatingLabels.LetterRatingLabelMedium
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "HIGH":
      return (
        <LetterRatingLabels.LetterRatingLabelHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "VHIGH":
      return (
        <LetterRatingLabels.LetterRatingLabelVHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
  }
}
