import { useIntl } from "react-intl";

import assertNever from "../../util/assertNever";
import Select from "../utils/Select";
import {
  LARGE_SCALE_FACTOR,
  MEDIUM_SCALE_FACTOR,
  X_LARGE_SCALE_FACTOR,
} from "../utils/Vectors/carbon-labels/constants";
import {
  CarbonLabelSizeOption,
  SizeDependentCarbonLabelType,
} from "./carbon-label/CarbonLabel";
import "./CarbonLableSizeSelect.css";

interface CarbonLabelSizeSelectProps {
  labelType: SizeDependentCarbonLabelType;
  selectedItem: CarbonLabelSizeOption;
  onSelect: (
    labelType: SizeDependentCarbonLabelType,
    size: CarbonLabelSizeOption
  ) => void;
}

export default function CarbonLabelSizeSelect(
  props: CarbonLabelSizeSelectProps
) {
  const { labelType, onSelect, selectedItem } = props;

  const intl = useIntl();

  const options: Array<CarbonLabelSizeOption> = [
    "small",
    "medium",
    "large",
    "x-large",
  ];

  const handleChange = (size: CarbonLabelSizeOption | null) => {
    if (size === null) {
      throw new Error(
        "Label size Select should be non-clearable and therefore should not return null on change"
      );
    }
    onSelect(labelType, size);
  };

  const renderOption = (item: CarbonLabelSizeOption): string => {
    if (item === "small") {
      return intl.formatMessage({
        id: "components/labels/LabelsSizeSelect:labelSizeOption:small",
        defaultMessage: "Original (1x)",
      });
    } else if (item === "medium") {
      return intl.formatMessage(
        {
          id: "components/labels/LabelsSizeSelect:labelSizeOption:medium",
          defaultMessage: "Medium ({mediumScaleFactor}x)",
        },
        { mediumScaleFactor: MEDIUM_SCALE_FACTOR }
      );
    } else if (item === "large") {
      return intl.formatMessage(
        {
          id: "components/labels/LabelsSizeSelect:labelSizeOption:large",
          defaultMessage: "Large ({largeScaleFactor}x)",
        },
        { largeScaleFactor: LARGE_SCALE_FACTOR }
      );
    } else if (item === "x-large") {
      return intl.formatMessage(
        {
          id: "components/labels/LabelsSizeSelect:labelSizeOption:xLarge",
          defaultMessage: "Extra large ({xLargeScaleFactor}x)",
        },
        { xLargeScaleFactor: X_LARGE_SCALE_FACTOR }
      );
    } else {
      assertNever(item, "Unsupported CarbonLabelSizeOption");
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="label-size-select-container"
        isClearable={false}
        dropdownArrow={"upDown"}
        options={options}
        optionKey={(item) => item}
        renderOption={renderOption}
        onChange={handleChange}
        value={selectedItem}
      />
    </div>
  );
}
