import * as Sentry from "@sentry/react";
import CSV from "comma-separated-values";
import { saveAs } from "file-saver";
import { gql } from "graphql-tag";
import { useRef, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedMessage, useIntl } from "react-intl";

import {
  EffectTypeAbsoluteValue,
  effectTypes,
  impactCategoryToEffectTypePerKg,
  useImpactCategoryEffectTypePerPortion,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { impactRatingToLetter } from "../../domain/impactRatings";
import {
  useExportIngredientImpactsByStage,
  useLandUse,
  useOrganizationSystemBoundaryIsCradleToGrave,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import { RecipePageName, useTracking } from "../../tracking";
import { useGraphQL } from "../graphql/GraphQLProvider";
import { fetchPages } from "../graphql/paging";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import ActionModal from "../utils/ActionModal";
import { SecondaryButton } from "../utils/Button";
import ErrorAlert from "../utils/ErrorAlert";
import { CsvData, SelectedCsvData } from "../utils/Vectors";
import { RecipesDropdownItem } from "./RecipesDropdown";
import {
  ExportRecipeImpactsCsvDropdownItem_Recipe as Recipe,
  ExportRecipeImpactsCsvDropdownItem_RecipeCountQuery as RecipeCountQuery,
  ExportRecipeImpactsCsvDropdownItem_RecipeCountQueryVariables as RecipeCountQueryVariables,
  ExportRecipeImpactsCsvDropdownItem_RecipesQuery as RecipesQuery,
  ExportRecipeImpactsCsvDropdownItem_RecipesQueryVariables as RecipesQueryVariables,
} from "./useExportRecipeImpactsCsvDropdownItem.graphql";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";
import useRecipeLabel from "./useRecipeLabel";

type RecipeImpact = Recipe["impact"];

let nextRequestKey = 1;

type ExportStatus =
  | { type: "inactive" }
  | {
      type: "downloading";
      downloadedRecipeCount: number;
      totalRecipeCount: number | null;
    }
  | { type: "error"; error: unknown };

type MissingStageImpactKey = [number, string, string, "Recipe" | "Ingredient"];

export default function useExportRecipeImpactsCsvDropdownItem({
  closeDropdown = () => {},
  pageName,
  recipeFilter,
}: {
  closeDropdown?: () => void;
  pageName: RecipePageName;
  recipeFilter: RecipeFilterWithExclude;
}): RecipesDropdownItem {
  const graphQL = useGraphQL();

  const exportIngredientImpactsByStage = useExportIngredientImpactsByStage();
  const effectTypeGhgPerPortion = useImpactCategoryEffectTypePerPortion(
    ImpactCategory.GHG
  );
  const effectTypeLandUsePerPortion = useImpactCategoryEffectTypePerPortion(
    ImpactCategory.LAND_USE
  );
  const effectTypeWaterUsePerPortion = useImpactCategoryEffectTypePerPortion(
    ImpactCategory.WATER_USE
  );
  const effectTypeGhgPerKg = impactCategoryToEffectTypePerKg(
    ImpactCategory.GHG
  );
  const effectTypeLandUsePerKg = impactCategoryToEffectTypePerKg(
    ImpactCategory.LAND_USE
  );
  const effectTypeWaterUsePerKg = impactCategoryToEffectTypePerKg(
    ImpactCategory.WATER_USE
  );
  const hasLandUseFeature = useLandUse();
  const hasWaterUseFeature = useWaterUse();
  const recipeTypeLabel = useRecipeLabel();
  const intl = useIntl();
  const showCradleToGraveImpactRating =
    useOrganizationSystemBoundaryIsCradleToGrave();
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;
  const { trackRecipeImpactsExported } = useTracking();
  const [exportStatus, setExportStatus] = useState<ExportStatus>({
    type: "inactive",
  });
  const activeRequestKey = useRef<number | null>(null);

  const { excludedIds, ...recipeFilterForQuery } = recipeFilter;

  function handleCancel() {
    activeRequestKey.current = null;
    setExportStatus({ type: "inactive" });
  }

  const formatImpact = (
    effectType: EffectTypeAbsoluteValue,
    impact: RecipeImpact,
    impactCategory: ImpactCategory
  ): string => {
    if (recipeImpactIsUnavailable(impact, impactCategory)) {
      return "";
    }
    const impactMagnitude = effectType.get(impact);
    return impactMagnitude === null ? "" : impactMagnitude.toString();
  };

  async function handleExport() {
    closeDropdown();
    try {
      setExportStatus({
        type: "downloading",
        downloadedRecipeCount: 0,
        totalRecipeCount: null,
      });
      const requestKey = nextRequestKey++;
      activeRequestKey.current = requestKey;

      const recipeCount = await fetchRecipeCount();
      if (activeRequestKey.current !== requestKey) {
        return;
      }
      setExportStatus({
        type: "downloading",
        downloadedRecipeCount: 0,
        totalRecipeCount: recipeCount,
      });

      let rows: Array<Array<string>>;
      rows = [
        [
          ...[
            intl.formatMessage(
              {
                id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeName",
                defaultMessage: "{recipeTypeLabel} Name",
              },
              { recipeTypeLabel: recipeTypeLabel.singularUppercase }
            ),
            intl.formatMessage(
              {
                id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeID",
                defaultMessage: "{recipeTypeLabel} ID",
              },
              { recipeTypeLabel: recipeTypeLabel.singularUppercase }
            ),
            intl.formatMessage(
              {
                id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeCode",
                defaultMessage: "{recipeTypeLabel} Code",
              },
              { recipeTypeLabel: recipeTypeLabel.singularUppercase }
            ),
            intl.formatMessage({
              id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeServings",
              defaultMessage: "Servings",
            }),
            ...(showCradleToGraveImpactRating
              ? [
                  intl.formatMessage(
                    {
                      id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/impactRating",
                      defaultMessage: "{recipeTypeLabel} GHG Rating",
                    },
                    { recipeTypeLabel: recipeTypeLabel.singularUppercase }
                  ),
                ]
              : []),
            `${effectTypeGhgPerKg.title(intl)} (${effectTypeGhgPerKg.unitString(
              intl
            )})`,
            `${effectTypeGhgPerPortion.title(
              intl
            )} (${effectTypeGhgPerPortion.unitString(intl)})`,
            ...(hasLandUseFeature
              ? [
                  `${effectTypeLandUsePerKg.title(
                    intl
                  )} (${effectTypeLandUsePerKg.unitString(intl)})`,
                  `${effectTypeLandUsePerPortion.title(
                    intl
                  )} (${effectTypeLandUsePerPortion.unitString(intl)})`,
                ]
              : []),
            ...(hasWaterUseFeature
              ? [
                  `${effectTypeWaterUsePerKg.title(
                    intl
                  )} (${effectTypeWaterUsePerKg.unitString(intl)})`,
                  `${effectTypeWaterUsePerPortion.title(
                    intl
                  )} (${effectTypeWaterUsePerPortion.unitString(intl)})`,
                ]
              : []),
          ],
          ...(exportIngredientImpactsByStage
            ? [
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeTotalImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - All Stages",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeFarmImpact",
                    defaultMessage: "{recipeTypeLabel} {ghgIntensity} - Farm",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeProcessingImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - Processing",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipePackagingImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - Packaging",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeTransportImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - Transport",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeRetailImpact",
                    defaultMessage: "{recipeTypeLabel} {ghgIntensity} - Retail",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeEndMileImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - End Mile",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeCookingImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - Cooking",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeFoodWasteImpact",
                    defaultMessage:
                      "{recipeTypeLabel} {ghgIntensity} - Food Waste",
                  },
                  {
                    recipeTypeLabel: recipeTypeLabel.singularUppercase,
                    ghgIntensity: effectTypes.ghgPerKg.title(intl),
                  }
                ),
                intl.formatMessage({
                  id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientName",
                  defaultMessage: "Ingredient Name",
                }),
                intl.formatMessage({
                  id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientImpactRating",
                  defaultMessage: "Ingredient GHG Rating",
                }),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientTotalImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - All Stages",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientFarmImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Farm",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientProcessingImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Processing",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientPackagingImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Packaging",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientTransportImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Transport",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientRetailImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Retail",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientEndMileImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - End Mile",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientCookingImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Cooking",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
                intl.formatMessage(
                  {
                    id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:csv/headers/recipeIngredientFoodWasteImpact",
                    defaultMessage: "Ingredient {ghgIntensity} - Food Waste",
                  },
                  { ghgIntensity: effectTypes.ghgPerKg.title(intl) }
                ),
              ]
            : []),
        ],
      ];
      let downloadedRecipeCount = 0;
      let missingImpactStages: Array<MissingStageImpactKey> = [];

      for await (const recipePage of fetchRecipePages()) {
        if (activeRequestKey.current !== requestKey) {
          return;
        }

        const filteredRecipePage = recipePage.filter(
          (recipe) => !recipe.impact.requiresClientAttention
        );

        for (const recipe of filteredRecipePage) {
          const getRecipeStageImpact = (stageName: string): number => {
            const stageValue = recipeStages.get(stageName);

            // If the recipe returns a non-null effects map, we expect every ghg key to be there
            if (stageValue === undefined && recipe.impact.effects !== null) {
              const missingStageImpactKey: MissingStageImpactKey = [
                recipe.id,
                recipe.name,
                stageName,
                "Recipe",
              ];

              // Add to missing stage impacts if it doesn't already exist
              if (missingImpactStages.indexOf(missingStageImpactKey) !== -1) {
                missingImpactStages.push(missingStageImpactKey);
              }
            }
            return stageValue ?? 0;
          };

          if (excludedIds?.includes(recipe.id)) {
            continue;
          }
          const recipeStages = new Map();
          for (const stage of recipe.impact.effects?.stages ?? []) {
            recipeStages.set(stage.codename, stage.impactPerRecipe);
          }

          const perRecipeColumns = [
            recipe.name,
            recipe.id.toString(),
            recipe.code ?? "",
            recipe.serves?.toString() ?? "1",
            ...(showCradleToGraveImpactRating
              ? [
                  recipe.impact.impactRating === null
                    ? ""
                    : impactRatingToLetter(recipe.impact.impactRating),
                ]
              : []),
            formatImpact(effectTypeGhgPerKg, recipe.impact, ImpactCategory.GHG),
            formatImpact(
              effectTypeGhgPerPortion,
              recipe.impact,
              ImpactCategory.GHG
            ),
            ...(hasLandUseFeature
              ? [
                  formatImpact(
                    effectTypeLandUsePerKg,
                    recipe.impact,
                    ImpactCategory.LAND_USE
                  ),
                  formatImpact(
                    effectTypeLandUsePerPortion,
                    recipe.impact,
                    ImpactCategory.LAND_USE
                  ),
                ]
              : []),
            ...(hasWaterUseFeature
              ? [
                  formatImpact(
                    effectTypeWaterUsePerKg,
                    recipe.impact,
                    ImpactCategory.WATER_USE
                  ),
                  formatImpact(
                    effectTypeWaterUsePerPortion,
                    recipe.impact,
                    ImpactCategory.WATER_USE
                  ),
                ]
              : []),
          ];

          if (!exportIngredientImpactsByStage) {
            rows.push(perRecipeColumns);
            continue;
          }

          for (const ingredient of recipe.impact.ingredients) {
            const ingredientStages = new Map();
            for (const stage of ingredient.effects?.stages ?? []) {
              ingredientStages.set(stage.codename, stage.impactPerRecipe);
            }
            const getIngredientStageImpact = (stageName: string): number => {
              const stageValue = ingredientStages.get(stageName);

              // If the ingredient returns a non-null effects map, we expect every ghg key to be there
              if (stageValue === undefined && ingredient.effects !== null) {
                const missingStageImpactKey: MissingStageImpactKey = [
                  ingredient.ingredientId,
                  ingredient.name,
                  stageName,
                  "Ingredient",
                ];
                missingImpactStages.push(missingStageImpactKey);
              }
              return stageValue ?? 0;
            };

            rows.push([
              ...perRecipeColumns,
              ...[
                (recipe.impact.effects?.ghgPerRecipe ?? "").toString(),
                (
                  getRecipeStageImpact("ghg_farm") +
                  getRecipeStageImpact("ghg_feed") +
                  getRecipeStageImpact("ghg_luc_burn") +
                  getRecipeStageImpact("ghg_luc_c_stock")
                ).toString(),
                getRecipeStageImpact("ghg_processing").toString(),
                getRecipeStageImpact("ghg_packaging").toString(),
                getRecipeStageImpact("ghg_tran_str").toString(),
                getRecipeStageImpact("ghg_retail").toString(),
                getRecipeStageImpact("ghg_end_mile").toString(),
                getRecipeStageImpact("ghg_cooking").toString(),
                (
                  getRecipeStageImpact("ghg_end_of_life") +
                  getRecipeStageImpact("ghg_loss")
                ).toString() ?? "",
                ingredient.name,
                ingredient.impactRating === null
                  ? ""
                  : impactRatingToLetter(ingredient.impactRating),
                (ingredient.effects?.ghgPerRecipe ?? "").toString(),
                (
                  getIngredientStageImpact("ghg_farm") +
                  getIngredientStageImpact("ghg_feed") +
                  getIngredientStageImpact("ghg_luc_burn") +
                  getIngredientStageImpact("ghg_luc_c_stock")
                ).toString() ?? "",
                getIngredientStageImpact("ghg_processing").toString(),
                getIngredientStageImpact("ghg_packaging").toString(),
                getIngredientStageImpact("ghg_tran_str").toString(),
                getIngredientStageImpact("ghg_retail").toString(),
                getIngredientStageImpact("ghg_end_mile").toString(),
                getIngredientStageImpact("ghg_cooking").toString(),
                (
                  getIngredientStageImpact("ghg_end_of_life") +
                  getIngredientStageImpact("ghg_loss")
                ).toString() ?? "",
              ],
            ]);
          }
          downloadedRecipeCount += 1;
        }

        setExportStatus({
          type: "downloading",
          downloadedRecipeCount,
          totalRecipeCount: recipeCount,
        });
      }

      if (missingImpactStages.length > 0) {
        Sentry.captureMessage(
          `CSV Export error - Missing impacts for: ${missingImpactStages.join(
            ",  "
          )}`,
          Sentry.Severity.Error
        );
      }

      const csv = new CSV(rows);
      const csvContent = new Blob([csv.encode()], {
        type: "text/plain;charset=utf-8",
      });

      saveAs(csvContent, "products.csv");
      setExportStatus({ type: "inactive" });
      trackRecipeImpactsExported({
        page: pageName,
        recipesExported: 2,
        success: true,
      });
    } catch (error: unknown) {
      setExportStatus({ type: "error", error });
      trackRecipeImpactsExported({
        page: pageName,
        recipesExported: 0,
        success: false,
      });
    }
  }

  async function fetchRecipeCount(): Promise<number> {
    const response = await graphQL.fetch<
      RecipeCountQuery,
      RecipeCountQueryVariables
    >({
      query: recipeCountQuery,
      variables: {
        recipeFilter: recipeFilterForQuery,
      },
    });

    return response.recipeCount - (excludedIds?.length ?? 0);
  }

  function fetchRecipePages(): AsyncGenerator<Array<Recipe>> {
    return fetchPages<Recipe>({
      fetchPage: async ({ after }) => {
        const response = await graphQL.fetch<
          RecipesQuery,
          RecipesQueryVariables
        >({
          query: recipesQuery,
          variables: {
            after,
            first: 50,
            recipeFilter: recipeFilterForQuery,
          },
        });
        return response.recipes;
      },
    });
  }

  const allRecipesSelected = recipeFilter.ids === undefined;

  return {
    action: {
      type: "button",
      onClick: handleExport,
    },
    Icon: allRecipesSelected ? CsvData : SelectedCsvData,
    render: (Component) => (
      <>
        <Component>
          {allRecipesSelected ? (
            <FormattedMessage
              id="components/recipes/ExportRecipeImpactsCsvDropdownItem:label"
              defaultMessage="Export all impacts as CSV"
            />
          ) : (
            <FormattedMessage
              id="components/recipes/ExportSelectedRecipeImpactsCsvDropdownItem:label"
              defaultMessage="Impacts as CSV"
            />
          )}
        </Component>
        <ExportStatusModal
          exportStatus={exportStatus}
          onCancel={handleCancel}
        />
      </>
    ),
  };
}

interface ExportStatusModalProps {
  exportStatus: ExportStatus;
  onCancel: () => void;
}

function ExportStatusModal(props: ExportStatusModalProps) {
  const { exportStatus, onCancel } = props;

  const intl = useIntl();

  if (exportStatus.type === "inactive") {
    return null;
  }

  return (
    <ActionModal
      show
      title={intl.formatMessage({
        id: "components/recipes/ExportRecipeImpactsCsvDropdownItem:exporting",
        defaultMessage: "Export Products",
      })}
    >
      <ActionModal.Body>
        {exportStatus.type === "downloading" && (
          <ProgressBar
            now={
              exportStatus.totalRecipeCount === null
                ? 0
                : (exportStatus.downloadedRecipeCount /
                    exportStatus.totalRecipeCount) *
                  100
            }
          />
        )}

        {exportStatus.type === "error" && (
          <ErrorAlert error={exportStatus.error} />
        )}
      </ActionModal.Body>
      <ActionModal.Footer>
        <div className="d-flex w-100 justify-content-between flex-row">
          <SecondaryButton onClick={onCancel}>
            <FormattedMessage
              id="components/recipes/ExportRecipeImpactsCsvDropdownItem:cancelExport"
              defaultMessage="Cancel"
            />
          </SecondaryButton>
        </div>
      </ActionModal.Footer>
    </ActionModal>
  );
}

const recipeCountQuery = gql`
  query ExportRecipeImpactsCsvDropdownItem_RecipeCountQuery(
    $recipeFilter: RecipeFilter!
  ) {
    recipeCount(filter: $recipeFilter)
  }
`;

const recipesQuery = gql`
  query ExportRecipeImpactsCsvDropdownItem_RecipesQuery(
    $after: String
    $first: Int!
    $recipeFilter: RecipeFilter!
  ) {
    recipes(after: $after, first: $first, filter: $recipeFilter) {
      edges {
        node {
          ...ExportRecipeImpactsCsvDropdownItem_Recipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment ExportRecipeImpactsCsvDropdownItem_Recipe on Recipe {
    id
    serves
    impact(excludePackaging: false, fetchStaleImpacts: false) {
      impactRating
      requiresClientAttention
      effects {
        ghgPerKg
        ghgPerRecipe
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRecipe
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
        stages {
          codename
          impactPerRecipe
        }
      }
      ingredients {
        ingredientId
        name
        impactRating
        effects {
          ghgPerRecipe
          stages {
            codename
            impactPerRecipe
          }
        }
      }
      unavailableLandUse
      unavailableWaterUse
    }
    code
    name
  }
`;
