import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemo } from "use-memo-one";

import useUserInfo from "../../data-store/useUserInfo";
import { useCountryOfOriginSourcing } from "../../services/useOrganizationFeatures";
import * as comparators from "../../util/comparators";
import { toMultiMap } from "../../util/maps";
import useRecipeLabel from "../recipes/useRecipeLabel";
import { Panel } from "../utils/Panel";
import { Table, TableColumn } from "../utils/Table";
import UpgradeRequestModal from "../utils/UpgradeRequestModal";
import { locationsToString } from "./ingredientOrigins";
import { IngredientOriginInformationModalTooltip } from "./IngredientsPage";
import {
  SimpleIngredientsTable_Location as Location,
  SimpleIngredientsTable_Recipe as Recipe,
  SimpleIngredientsTable_WeightedLocationOption as WeightedLocationOption,
} from "./SimpleIngredientsTable.graphql";
import "./IngredientsTable.css";

interface IngredientSummary {
  foodClassId: number;
  key: number;
  name: string;
  recipes: Array<Recipe>;
}

function summarizeIngredients(
  recipes: Array<Recipe>
): Array<IngredientSummary> {
  const allIngredients = recipes.flatMap((recipe) =>
    recipe.ingredients.map((ingredient) => ({ ingredient, recipe }))
  );
  const groupedIngredients = toMultiMap(
    allIngredients.flatMap(({ ingredient, recipe }) =>
      ingredient.ignorable || ingredient.foodClass === null
        ? []
        : [
            [
              ingredient.foodClass.id,
              { foodClass: ingredient.foodClass, ingredient, recipe },
            ],
          ]
    )
  );
  const ingredientSummaries = Array.from(groupedIngredients).map(
    ([key, ingredients]) => {
      const { foodClass } = ingredients[0];

      return {
        foodClassId: foodClass.id,
        key,
        name: foodClass.name,
        recipes: ingredients.map((ingredient) => ingredient.recipe),
      };
    }
  );
  return ingredientSummaries.sort(
    comparators.map(
      (summary) => summary.name,
      comparators.stringSensitivityBase
    )
  );
}

const ingredientSummaryKey = (summary: IngredientSummary) => summary.key;

interface SimpleIngredientsTableProps {
  locations: Array<Location>;
  recipes: Array<Recipe>;
  selectFoodClassId: (foodClassId: number | null) => void;
  weightedLocationOptions: Array<WeightedLocationOption>;
}

export default function SimpleIngredientsTable(
  props: SimpleIngredientsTableProps
) {
  const { locations, recipes, selectFoodClassId, weightedLocationOptions } =
    props;
  // A simplified version of the IngredientsTable, without impacts.
  // Use this when loading ingredient impacts is too slow.

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();
  const [userInfo] = useUserInfo();

  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);
  const canChangeIngredientSourcing = useCountryOfOriginSourcing();

  const ingredientSummaries = useMemo(() => {
    return summarizeIngredients(recipes);
  }, [recipes]);

  const getLocationById = (locationId: number) =>
    locations.find((location) => locationId === location.id) ?? null;

  const locationsByFoodClassId = new Map(
    weightedLocationOptions.map((locationOption) => [
      locationOption.foodClassId,
      locationOption.locationProportions.map(
        (locationProportion) => locationProportion.locationId
      ),
    ])
  );

  const locationsForFoodClassId = (foodClassId: number): string => {
    return locationsToString(
      locationsByFoodClassId.get(foodClassId)?.map(getLocationById),
      intl
    );
  };

  const columns: Array<TableColumn<IngredientSummary>> = [
    {
      key: "ingredient",
      className: "medium-font IngredientsTable_IngredientColumn",
      label: intl.formatMessage({
        id: "components/ingredients/SimpleIngredientsTable:columns/ingredient/label",
        defaultMessage: "Ingredient",
      }),
      renderCell: (summary) => summary.name,
      sortComparator: comparators.map(
        (summary) => summary.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "origin",
      label: intl.formatMessage({
        id: "components/ingredients/SimpleIngredientsTable:columns/origin/label",
        defaultMessage: "Ingredient Origin",
      }),
      labelPrefix: <IngredientOriginInformationModalTooltip />,
      locked: !canChangeIngredientSourcing,
      lockedMessage: (
        <FormattedMessage
          id="components/ingredients/IngredientsTable:columns/origin/lockedMessage"
          defaultMessage="Choose country-specific ingredient origins"
        />
      ),
      onLockClick: () => setShowUpgradeRequestModal(true),
      renderCell: (summary) => {
        const label = locationsForFoodClassId(summary.foodClassId);

        return userInfo.isReadonly || !canChangeIngredientSourcing ? (
          label
        ) : (
          <div
            className="action-link"
            onClick={() => selectFoodClassId(summary.foodClassId)}
          >
            {label}
          </div>
        );
      },
      sortComparator: comparators.map(
        (summary) => locationsForFoodClassId(summary.foodClassId),
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "recipeCount",
      align: "right",
      label: recipeLabel.pluralUppercase,
      renderCell: (summary) => summary.recipes.length,
      sortComparator: comparators.map(
        (summary) => summary.recipes.length,
        comparators.number
      ),
    },
  ];

  return (
    <div style={{ minHeight: 0 }}>
      <Panel className="IngredientsTable_Panel">
        <Table<IngredientSummary>
          fullWidth
          columns={columns}
          defaultSort="ingredient"
          rowKey={ingredientSummaryKey}
          rows={ingredientSummaries}
        />
      </Panel>
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </div>
  );
}

SimpleIngredientsTable.fragments = {
  recipe: gql`
    fragment SimpleIngredientsTable_Recipe on Recipe {
      ingredients {
        ...SimpleIngredientsTable_RecipeIngredient
      }
    }

    fragment SimpleIngredientsTable_RecipeIngredient on RecipeIngredient {
      foodClass {
        id
        name
      }
      id
      ignorable
    }
  `,

  location: gql`
    fragment SimpleIngredientsTable_Location on Location {
      id
    }
  `,

  weightedLocationOption: gql`
    fragment SimpleIngredientsTable_WeightedLocationOption on WeightedLocationOption {
      foodClassId
      locationProportions {
        locationId
      }
    }
  `,
};
