import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useCallbackOne } from "use-memo-one";

import { DataStore, SupportedRegion, useDataStore } from "../../../data-store";
import { FOODSTEPS_SUPPORT_EMAIL } from "../../../domain/email";
import { SignUpRequestInput, useSignUp } from "../../../services/api/useSignUp";
import { useTracking } from "../../../tracking";
import assertNever from "../../../util/assertNever";
import { Status } from "../../../util/statuses";
import { usePages } from "../../pages";
import StatusDisplay from "../../StatusDisplay";
import ActionModal from "../../utils/ActionModal";
import { PrimaryButtonLink } from "../../utils/Button";
import Card from "../../utils/Card";
import usePromise from "../../utils/usePromise";
import ConfirmSignUpForm from "./ConfirmSignUpForm";
import EndMileTypeQuestionsForm from "./EndMileTypeQuestionsForm";
import RegionSelectionForm from "./RegionSelectionForm";
import SignUpDetailsForm, { SignUpDetails } from "./SignUpDetailsForm";
import "./SignUpPage.css";

type UserOrganizationDetailsState = { type: "userOrganizationDetails" };

type RegionSelectionState = {
  type: "regionSelection";
} & Pick<SignUpRequestInput, keyof SignUpDetails>;

type EndMileTypeDecisionTreeState = {
  type: "endMileTypeDecisionTree";
} & Omit<RegionSelectionState, "type"> &
  Pick<SignUpRequestInput, "locationId">;

type ConfirmState = {
  type: "confirm";
} & Omit<EndMileTypeDecisionTreeState, "type"> &
  Pick<SignUpRequestInput, "endMileTypeDecisionTreeResponse">;

type State =
  | UserOrganizationDetailsState
  | RegionSelectionState
  | EndMileTypeDecisionTreeState
  | ConfirmState;

export default function SignUpPage() {
  const signUp = useSignUp();
  const pages = usePages();
  const {
    identifyUserBeforeSignUp,
    trackSignUpPersonalDetailsSubmitted,
    trackSignUpConsumptionCountrySubmitted,
  } = useTracking();

  const { parentOrganizationId, signUpOverride } =
    pages.SignUp.useQueryParams();
  const parentOrganizationDataStatus =
    useParentOrganizationData(parentOrganizationId);

  const [state, setState] = useState<State>({
    type: "userOrganizationDetails",
  });
  const [showSignUpCompletedModal, setShowSignUpCompletedModal] =
    useState(false);

  const handleDetailsComplete = (
    state: UserOrganizationDetailsState,
    details: SignUpDetails
  ) => {
    trackSignUpPersonalDetailsSubmitted();
    identifyUserBeforeSignUp({
      email: details.email,
      firstName: details.firstName,
      lastName: details.lastName,
      organizationName: details.organizationName,
    });
    setState({ type: "regionSelection", ...details });
  };

  const handleRegionSelectionComplete = (
    state: RegionSelectionState,
    selectedRegion: SupportedRegion
  ) => {
    trackSignUpConsumptionCountrySubmitted({
      countryName: selectedRegion.name,
    });
    setState({
      ...state,
      type: "endMileTypeDecisionTree",
      locationId: selectedRegion.id,
    });
  };

  const handleQuestionsComplete = (
    state: EndMileTypeDecisionTreeState,
    endMileTypeDecisionTreeResponse: string
  ) => {
    setState({
      ...state,
      type: "confirm",
      endMileTypeDecisionTreeResponse,
    });
  };

  const handleSubmit = async (
    state: ConfirmState,
    emailMarketingConsent: boolean,
    trackingProviderDistinctId: string
  ) => {
    const requestInput: SignUpRequestInput = {
      ...state,
      signUpOverride,
      emailMarketingConsent,
      parentOrganizationId,
      trackingProviderDistinctId,
    };
    await signUp(requestInput);
    setShowSignUpCompletedModal(true);
  };

  const header = (
    <h2 className="SignUpCard_Header">
      <FormattedMessage
        id="components/account/sign-up/SignUpPage:signUp"
        defaultMessage="Sign Up"
      />
    </h2>
  );

  const body = (
    <StatusDisplay status={parentOrganizationDataStatus}>
      {(parentOrganizationData) => (
        <div className="w-100 justify-content-center mx-auto">
          {state.type === "userOrganizationDetails" ? (
            <SignUpDetailsForm
              onComplete={(details) => handleDetailsComplete(state, details)}
            />
          ) : state.type === "regionSelection" ? (
            <RegionSelectionForm
              onComplete={(selectedRegion) =>
                handleRegionSelectionComplete(state, selectedRegion)
              }
            />
          ) : state.type === "endMileTypeDecisionTree" ? (
            <EndMileTypeQuestionsForm
              onComplete={(endMileTypeDecisionTreeResponse) =>
                handleQuestionsComplete(state, endMileTypeDecisionTreeResponse)
              }
            />
          ) : state.type === "confirm" ? (
            <ConfirmSignUpForm
              onComplete={(emailMarketingConsent, trackingProviderDistinctId) =>
                handleSubmit(
                  state,
                  emailMarketingConsent,
                  trackingProviderDistinctId
                )
              }
              parentOrganizationName={
                parentOrganizationData?.organizationName ?? null
              }
            />
          ) : (
            assertNever(state, "invalid state type")
          )}
        </div>
      )}
    </StatusDisplay>
  );

  const footer = (
    <div className="SignUpPage_Footer">
      <FormattedMessage
        id="components/account/sign-up/SignUpPage:signInPrompt"
        defaultMessage="Already have an account? <a>Log in</a>."
        values={{
          a: (chunks: React.ReactNode) => <Link to={"/"}>{chunks}</Link>,
        }}
      />
    </div>
  );

  const modal = (
    <ActionModal
      show={showSignUpCompletedModal}
      title={
        <FormattedMessage
          id="components/account/sign-up/SignUpPage:signUpCompletedModalTitle"
          defaultMessage="Thank you for signing up to Foodsteps"
        />
      }
    >
      <ActionModal.Body>
        <FormattedMessage
          id="components/account/sign-up/SignUpPage:signUpCompletedModalBody"
          defaultMessage="You’ll receive an email within one business day with the next steps to set up your Foodsteps account.{br}
            Got any questions? <a>Get in touch</a>."
          values={{
            a: (chunks: React.ReactNode) => (
              <a href={`mailto:${FOODSTEPS_SUPPORT_EMAIL}`}>{chunks}</a>
            ),
            br: <br />,
          }}
        />
      </ActionModal.Body>
      <ActionModal.Footer>
        <div className="d-flex w-100 justify-content-between">
          <div>
            <PrimaryButtonLink to={pages.LogIn.url}>
              <FormattedMessage
                id="components/account/sign-up/SignUpPage:signUpCompletedModalOk"
                defaultMessage="Ok"
              />
            </PrimaryButtonLink>
          </div>
        </div>
      </ActionModal.Footer>
    </ActionModal>
  );

  return (
    <div className="SignUpPage_Background bg-light">
      <Card className="SignUpPage_Card">
        {header}
        <hr className="my-4" />
        {body}
        <hr className="my-4" />
        {footer}
        {modal}
      </Card>
    </div>
  );
}

function useParentOrganizationData(
  parentOrganizationId: string | null
): Status<Awaited<
  ReturnType<DataStore["fetchPublicOrganizationData"]>
> | null> {
  const { fetchPublicOrganizationData } = useDataStore();
  const promiseCallback = useCallbackOne(() => {
    if (parentOrganizationId === null) {
      return Promise.resolve(null);
    }

    return fetchPublicOrganizationData(parentOrganizationId);
  }, [parentOrganizationId, fetchPublicOrganizationData]);

  const [status] = usePromise(promiseCallback);

  return status;
}
