import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "../App.scss";
import { RawTrackingProvider, Tracking } from "../tracking";
import accountStories from "./account/stories";
import graphsStories from "./graphs/Graphs.stories";
import ingredientsStories from "./ingredients/stories";
import labelsStories from "./labels/stories";
import navigationStories from "./navigation/stories";
import packagingMaterialStories from "./packaging-materials/stories";
import packagingStories from "./packaging/stories";
import productEditorStories from "./recipes/ProductEditor/ProductEditor.stories";
import recipeEditorStories from "./recipes/RecipeEditor/RecipeEditor.stories";
import recipeStories from "./recipes/stories";
import sharingStories from "./sharing/stories";
import subscriptionStories from "./subscriptions/stories";
import tagsStories from "./tags/stories";
import transportModeStories from "./transport-modes/stories";
import transportStorageMethodStories from "./transport-storage-methods/stories";
import typographyStories from "./typography/stories";
import utilsStories from "./utils/stories";

const stories: Array<StoryNode> = [
  accountStories,
  graphsStories,
  ingredientsStories,
  labelsStories,
  navigationStories,
  packagingStories,
  packagingMaterialStories,
  productEditorStories,
  recipeEditorStories,
  recipeStories,
  sharingStories,
  subscriptionStories,
  tagsStories,
  transportModeStories,
  transportStorageMethodStories,
  typographyStories,
  utilsStories,
].sort((left, right) => left.name.localeCompare(right.name));

interface StorySet {
  name: string;
  children: Array<StoryNode>;
  render?: never;
}

interface Story {
  name: string;
  children?: never;
  render: () => React.ReactNode;
}

type StoryNode = StorySet | Story;

export default function StoriesPage() {
  const location = useLocation();
  const match = /^\/_stories\/(.*)$/.exec(location.pathname);
  const storyNames =
    match === null
      ? []
      : match[1].split("/").map((name) => decodeURIComponent(name));
  const story = findStory(storyNames);

  const tracking = createTracking();

  return (
    <div className="row" style={{ height: "100vh" }}>
      <div className="col-3 h-100 overflow-auto">
        <StoriesNav stories={stories} />
      </div>
      <div
        className="col-9 d-flex flex-column p-4"
        key={location.pathname}
        style={{ backgroundColor: "#eee" }}
      >
        {story && (
          <>
            <h1 className="h5">{storyNames.join(": ")}</h1>
            <div className="flex-grow-1 mt-4">
              <Viewport>
                <RawTrackingProvider value={tracking}>
                  {story.render()}
                </RawTrackingProvider>
              </Viewport>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function createTracking(): Tracking {
  return new Proxy(
    {},
    {
      get(target: {}, propertyName: string | symbol, receiver: any): any {
        return (...args: Array<any>) => {
          console.log(
            `Tracking: ${String(propertyName)} ${args
              .map((arg) => JSON.stringify(arg))
              .join(" ")}`
          );
        };
      },
    }
  ) as Tracking;
}

interface StoriesNavProps {
  stories: Array<StoryNode>;
}

function StoriesNav(props: StoriesNavProps) {
  const { stories } = props;

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="p-2">
      <div className="form-inline mb-4">
        <label>Search</label>
        <input
          autoFocus
          className="form-control flex-grow-1 ml-2"
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
        />
      </div>
      <StoriesNavList
        ancestors={[]}
        stories={filterStories(stories, searchTerm)}
      />
    </div>
  );
}

function filterStories(
  stories: Array<StoryNode>,
  searchTerm: string
): Array<StoryNode> {
  return stories.flatMap((story) => {
    if (matchesSearchTerm(story.name, searchTerm)) {
      return [story];
    }

    if (!story.children) {
      return [];
    }

    const children = filterStories(story.children, searchTerm);
    if (children.length > 0) {
      return [{ ...story, children }];
    }

    return [];
  });
}

function matchesSearchTerm(name: string, searchTerm: string): boolean {
  return name.toLowerCase().includes(searchTerm.toLowerCase());
}

interface StoriesNavListProps {
  ancestors: Array<StoryNode>;
  stories: Array<StoryNode>;
}

function StoriesNavList(props: StoriesNavListProps) {
  const { ancestors, stories } = props;

  return (
    <ul>
      {stories.map((story) => (
        <li key={story.name}>
          {story.children && (
            <>
              {story.name}
              <StoriesNavList
                ancestors={[...ancestors, story]}
                stories={story.children}
              />
            </>
          )}
          {story.render && <StoryLink ancestors={ancestors} story={story} />}
        </li>
      ))}
    </ul>
  );
}

interface StoryLinkProps {
  ancestors: Array<StoryNode>;
  story: Story;
}

function StoryLink(props: StoryLinkProps) {
  const { ancestors, story } = props;
  const path = `/_stories/${[...ancestors, story]
    .map((ancestor) => encodeURIComponent(ancestor.name))
    .join("/")}`;
  return <Link to={path}>{story.name}</Link>;
}

function findStory(storyNames: Array<string>): Story | null {
  let currentStories = stories;
  for (let index = 0; index < storyNames.length; index++) {
    const name = storyNames[index];
    const storyNode = currentStories.find((story) => story.name === name);
    if (storyNode === undefined) {
      return null;
    } else if (storyNode.children) {
      currentStories = storyNode.children;
    } else {
      return storyNode;
    }
  }
  return null;
}

interface ViewportProps {
  children: React.ReactNode;
}

function Viewport(props: ViewportProps) {
  const { children } = props;

  return (
    <div className="h-100 bg-white" style={{ border: "2px solid gray" }}>
      {children}
    </div>
  );
}
