import { gql } from "graphql-tag";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { IngredientInput } from "../../../__generated__/globalTypes";
import AvailableIngredient from "../../../domain/AvailableIngredient";
import { ingredientQuantityUnits } from "../../../domain/units";
import {
  useRequestImpactAnalysis,
  useSubproducts,
} from "../../../services/useOrganizationFeatures";
import { useTracking } from "../../../tracking";
import assertNever from "../../../util/assertNever";
import { useOrganizationId } from "../../organizations/OrganizationProvider";
import RecipeSelect, { Recipe as Subrecipe } from "../../recipes/RecipeSelect";
import AutoSuggest from "../../utils/AutoSuggest";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult, { combineReadResultsArray } from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import TooltipOverlay from "../../utils/TooltipOverlay";
import UpgradeRequestModal from "../../utils/UpgradeRequestModal";
import { Private as PrivateIcon } from "../../utils/Vectors";
import * as RecipeIngredientLinking from "../RecipeIngredientLinking";
import RecipeEditorLabel from "./RecipeEditorLabel";
import {
  RecipeIngredientsEditor_Recipe as Recipe,
  RecipeIngredientsEditor_RecipeIngredient as Ingredient,
} from "./RecipeIngredientsEditor.graphql";
import "./RecipeIngredientsEditor.css";

export const label = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipeIngredientsEditor:label"
    defaultMessage="Ingredients"
  />
);

export type Value = Array<EditorIngredient>;

export enum IngredientType {
  SIMPLE = "SIMPLE",
  SUBRECIPE = "SUBRECIPE",
}

type IngredientTypeOption = {
  ingredientType: IngredientType;
  isDisabled?: boolean;
};

export interface EditorIngredient {
  existingIngredient: Ingredient | null;
  foodClassId: number | null;
  ingredientType: IngredientType;
  invalidName: boolean;
  invalidUseRecipe: boolean;
  key: string;
  name: string;
  quantity: FloatInput.Value;
  unit: string;
  useRecipe: Subrecipe | null;
}

export function initialValue(recipe: Recipe | undefined): Value {
  return recipe === undefined
    ? [blankIngredient()]
    : recipe.ingredients.map(existingIngredient);
}

let nextNewIngredientId = 1;

const defaultUnit = "g";

export function blankIngredient(): EditorIngredient {
  return {
    existingIngredient: null,
    foodClassId: null,
    ingredientType: IngredientType.SIMPLE,
    invalidName: false,
    invalidUseRecipe: false,
    key: "new_" + nextNewIngredientId++,
    name: "",
    quantity: FloatInput.initialValue(null),
    unit: defaultUnit,
    useRecipe: null,
  };
}

export function existingIngredient(ingredient: Ingredient): EditorIngredient {
  return {
    existingIngredient: ingredient,
    foodClassId: ingredient.foodClassId,
    ingredientType:
      ingredient.useRecipe === null
        ? IngredientType.SIMPLE
        : IngredientType.SUBRECIPE,
    invalidName: false,
    invalidUseRecipe: false,
    key: "existing_" + ingredient.id,
    name: ingredient.name,
    quantity: FloatInput.initialValue(ingredient.quantity),
    unit: ingredient.unit ?? defaultUnit,
    useRecipe: ingredient.useRecipe,
  };
}

interface RecipeIngredientsEditorProps {
  availableIngredients: Array<AvailableIngredient>;
  canDeleteAllIngredients?: boolean;
  existingRecipe: Recipe | undefined;
  instructions?: string;
  showQuantity?: boolean;
  onChange: (ingredients: Value) => void;
  value: Value;
}

export function RecipeIngredientsEditor(props: RecipeIngredientsEditorProps) {
  const {
    availableIngredients,
    canDeleteAllIngredients = false,
    existingRecipe,
    instructions,
    showQuantity = true,
    onChange,
    value,
  } = props;

  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);

  const subproductLockedMessage = (
    <FormattedMessage
      id="components/recipes/RecipeEditor/RecipeIngredientEditor:subproductLockedMessage"
      defaultMessage="Use your own products as inputs"
    />
  );

  const availableIngredientsByName = useMemo(
    () =>
      new Map(
        availableIngredients.map((ingredient) => [ingredient.name, ingredient])
      ),
    [availableIngredients]
  );

  const resetErrorState = (value: EditorIngredient) => ({
    ...value,
    invalidName: false,
    invalidUseRecipe: false,
  });

  return (
    <div className="RecipeIngredientsEditor__container">
      <div className="form-group">
        <RecipeEditorLabel className="mb-2">{label}</RecipeEditorLabel>
        <IngredientsHelpModal />
        {instructions && <p className="text-muted w-75">{instructions}</p>}
        <TableEditor
          blank={blankIngredient}
          onChange={onChange}
          renderRow={({
            onChange,
            onDelete,
            rowIndex,
            value: ingredientValue,
          }) => (
            <RecipeIngredientEditor
              availableIngredients={availableIngredients}
              availableIngredientsByName={availableIngredientsByName}
              canDelete={
                canDeleteAllIngredients || rowIndex !== 0 || value.length > 1
              }
              existingRecipe={existingRecipe}
              showQuantity={showQuantity}
              onChange={(ingredientValue) =>
                onChange({
                  ...resetErrorState(ingredientValue),
                })
              }
              onDelete={onDelete}
              value={ingredientValue}
              onLockClick={() => setShowUpgradeRequestModal(true)}
              subproductLockedMessage={subproductLockedMessage}
            />
          )}
          showAddButton={true}
          value={value}
        />
      </div>
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </div>
  );
}

export function readIngredient(
  ingredient: EditorIngredient
): ReadResult<EditorIngredient, IngredientInput> {
  let name: string;
  let invalidName: boolean;

  let useRecipeId: number | null;
  let invalidUseRecipe: boolean;

  let foodClassId: number | null;

  if (ingredient.ingredientType === IngredientType.SIMPLE) {
    name = ingredient.name;
    invalidName = ingredient.name.trim() === "";

    useRecipeId = null;
    invalidUseRecipe = false;

    foodClassId = ingredient.foodClassId;
  } else if (ingredient.ingredientType === IngredientType.SUBRECIPE) {
    name = ingredient.useRecipe?.name ?? "";
    invalidName = false;

    useRecipeId = ingredient.useRecipe?.id ?? null;
    invalidUseRecipe = useRecipeId === null;

    foodClassId = null;
  } else {
    assertNever(ingredient.ingredientType, "unknown ingredient type");
  }

  // The condition is deliberately > 0 and not >= 0.  We have a database constraint enforcing this.
  const quantity = FloatInput.read({
    value: ingredient.quantity,
    validationCondition: (value) => value > 0,
  });

  const newEditorValue: EditorIngredient = {
    ...ingredient,
    invalidName,
    invalidUseRecipe,
    quantity: quantity.value,
  };

  if (invalidName || invalidUseRecipe || quantity.hasError) {
    return {
      hasError: true,
      value: newEditorValue,
    };
  } else {
    return {
      hasError: false,
      input: {
        foodClassId,
        id: ingredient.existingIngredient?.id ?? null,
        name,
        quantity: quantity.input,
        unit: ingredient.unit,
        useRecipeId,
      },
      value: newEditorValue,
    };
  }
}

export function read(value: Value): ReadResult<Value, Array<IngredientInput>> {
  return combineReadResultsArray(value.map(readIngredient));
}

interface RecipeIngredientEditorProps {
  availableIngredients: Array<AvailableIngredient>;
  availableIngredientsByName: Map<string, AvailableIngredient>;
  canDelete: boolean;
  existingRecipe: Recipe | undefined;
  showQuantity: boolean;
  onChange: (ingredient: EditorIngredient) => void;
  onDelete: () => void;
  value: EditorIngredient;
  onLockClick?: () => void;
  subproductLockedMessage?: React.ReactNode;
}

function RecipeIngredientEditor(props: RecipeIngredientEditorProps) {
  const {
    availableIngredients,
    availableIngredientsByName,
    canDelete,
    existingRecipe,
    onLockClick,
    subproductLockedMessage,
    showQuantity,
    onChange,
    onDelete,
    value,
  } = props;

  const intl = useIntl();
  const [organizationId] = useOrganizationId();
  const { trackRecipeEditIngredientTypeChanged } = useTracking();
  const canRequestImpactAnalysis = useRequestImpactAnalysis();
  const canUseSubproducts = useSubproducts();

  const allIngredientTypeOptions: Array<IngredientTypeOption> = [
    { ingredientType: IngredientType.SIMPLE, isDisabled: false },
    {
      ingredientType: IngredientType.SUBRECIPE,
      isDisabled: !canUseSubproducts,
    },
  ];

  function onIngredientTypeChange(ingredientType: IngredientType | null) {
    if (ingredientType === null) {
      throw Error("Ingredient type cannot be null.");
    }
    const allowedUnits = ingredientQuantityUnits({
      isSubrecipe: ingredientType === IngredientType.SUBRECIPE,
    });
    const unit = allowedUnits.map((unit) => unit.value).includes(value.unit)
      ? value.unit
      : defaultUnit;

    onChange({
      ...value,
      ingredientType,
      unit,
    });

    trackRecipeEditIngredientTypeChanged({
      recipeId: existingRecipe?.id ?? null,
      recipeName: existingRecipe?.name ?? null,
      newIngredientType: ingredientType,
    });
  }

  const iconForIngredientType = (
    ingredientTypeOption: IngredientTypeOption
  ) => {
    return ingredientTypeOption.isDisabled ? (
      <div className="private-icon">
        <TooltipOverlay
          id="components/recipes/RecipeEditor/RecipeIngredientEditor:privateIconTooltip"
          overlay={subproductLockedMessage}
          placement="right"
          style={{ maxWidth: "144px", marginTop: "2px" }}
        >
          <PrivateIcon width={16} handleClick={onLockClick} />
        </TooltipOverlay>
      </div>
    ) : null;
  };

  const renderIngredientType = (ingredientTypeOption: IngredientTypeOption) => {
    if (ingredientTypeOption.ingredientType === IngredientType.SIMPLE) {
      return intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeIngredientsEditor:ingredientType/ingredient",
        defaultMessage: "Ingredient",
      });
    } else if (
      ingredientTypeOption.ingredientType === IngredientType.SUBRECIPE
    ) {
      return intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeIngredientsEditor:ingredientType/subrecipe",
        defaultMessage: "Product",
      });
    } else {
      assertNever(
        ingredientTypeOption.ingredientType,
        "Unhandled ingredient type."
      );
    }
  };

  const deleteColumnWidth = "20px";
  const recipeOrIngredientSelectClassName = showQuantity
    ? "RecipeIngredientsEditor_RecipeOrIngredientSelect_Short"
    : "RecipeIngredientsEditor_RecipeOrIngredientSelect_Long";

  return (
    <tr key={value.key}>
      {showQuantity && (
        <>
          <td className="pr-1" style={{ width: "6em" }}>
            <FloatInput.FloatInput
              id="recipeIngredientEditorAmount"
              onChange={(quantity) => onChange({ ...value, quantity })}
              value={value.quantity}
            />
          </td>
          <td className="pr-1" style={{ width: "6em" }}>
            <select
              id="recipeIngredientEditorUnit"
              placeholder={intl.formatMessage({
                id: "components/recipes/RecipeEditor/RecipeIngredientsEditor:unitPlaceholder",
                defaultMessage: "Unit",
              })}
              onChange={(event) =>
                onChange({ ...value, unit: event.target.value })
              }
              className="form-control m-0 pr-1 text-right"
              value={value.unit}
            >
              {ingredientQuantityUnits({
                isSubrecipe: value.ingredientType === IngredientType.SUBRECIPE,
              }).map((unit) => (
                <option key={unit.value} value={unit.value}>
                  {unit.label}
                </option>
              ))}
            </select>
          </td>
        </>
      )}
      <td className="pr-3">
        {value.ingredientType === IngredientType.SIMPLE ? (
          <AutoSuggest
            allSuggestions={availableIngredients}
            className={recipeOrIngredientSelectClassName}
            forceSelection={!canRequestImpactAnalysis}
            inputError={value.invalidName}
            getSuggestionValue={(availableIngredient) =>
              availableIngredient.name
            }
            onChange={(name) =>
              onChange({
                ...value,
                name,
                foodClassId:
                  value.existingIngredient === null ||
                  name !== value.existingIngredient.name
                    ? availableIngredientsByName.get(name)?.id ?? null
                    : value.existingIngredient.foodClassId,
              })
            }
            placeholder={intl.formatMessage({
              id: "components/recipes/RecipeEditor/RecipeIngredientsEditor:ingredientAutoSuggestPlaceholder",
              defaultMessage: "Search for an ingredient",
            })}
            value={value.name}
            render={(props) => (
              <div className="input-group">
                <input id="recipeIngredientEditorIngredientInput" {...props} />
                <div className="input-group-append">
                  <span
                    className="input-group-text text-middle"
                    style={{ lineHeight: "1rem" }}
                  >
                    <RecipeIngredientLinking.Icon
                      ingredient={{
                        foodClassId: value.foodClassId,
                      }}
                    />
                  </span>
                </div>
              </div>
            )}
          />
        ) : (
          <div className="w-100">
            <RecipeSelect
              className={recipeOrIngredientSelectClassName}
              hasError={value.invalidUseRecipe}
              filter={{ usableAsIngredientForOrganizationId: organizationId }}
              onChange={(useRecipe) => onChange({ ...value, useRecipe })}
              value={value.useRecipe}
            />
          </div>
        )}
      </td>
      <td className="pr-2">
        <Select<IngredientTypeOption>
          className="RecipeIngredientsEditor_IngredientOrProductSelect"
          isClearable={false}
          onChange={(ingredientTypeOption: IngredientTypeOption | null) =>
            onIngredientTypeChange(ingredientTypeOption?.ingredientType ?? null)
          }
          value={
            allIngredientTypeOptions.find(
              (option) => option.ingredientType === value.ingredientType
            )!
          }
          options={allIngredientTypeOptions}
          optionKey={renderIngredientType}
          renderOption={renderIngredientType}
          iconForOption={iconForIngredientType}
        />
      </td>
      {canDelete ? (
        <TableEditor.DeleteCell onDelete={onDelete} width={deleteColumnWidth} />
      ) : (
        <td style={{ width: deleteColumnWidth }}></td>
      )}
    </tr>
  );
}

function IngredientsHelpModal() {
  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeIngredientsEditor:ingredientsTooltipModal/title"
          defaultMessage="Ingredients"
        />
      }
    >
      <FormattedMessage
        id="components/recipes/RecipeEditor/RecipeIngredientsEditor:ingredientsTooltipModal/cannotFindIngredient"
        defaultMessage="
        <p>Search and choose from ingredient options available in our database.</p>
        <p>If you can not find an ingredient and are on a Foodsteps plan that includes ingredient research, leave the full name typed into the box and our data team will investigate a solution. To ensure a more accurate impact calculation, avoid selecting close matches from our database.</p>
        <p>For each ingredient that you produce yourself, create them separately as products and then use them in the products in which they are inputs.</p>
        "
        values={{
          p: (chunks: React.ReactNode) => <p>{chunks}</p>,
        }}
      />
    </HelpModalTooltip>
  );
}

export const fragments = {
  recipe: gql`
    fragment RecipeIngredientsEditor_Recipe on Recipe {
      id
      ingredients {
        ...RecipeIngredientsEditor_RecipeIngredient
      }
      name
    }

    fragment RecipeIngredientsEditor_RecipeIngredient on RecipeIngredient {
      foodClassId
      id
      name
      quantity
      unit
      useRecipe {
        id
        name
      }
    }
  `,
};
