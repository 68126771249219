import escapeRegExp from "lodash/escapeRegExp";
import { v4 as uuidv4 } from "uuid";

import { parseUnit, allUnitStringsForParsing } from "../../domain/units";

export interface ParsedIngredient {
  id: string;
  name: string;
  quantity: number;
  subrecipeId: number | null;
  unit: string;
}

export function parseIngredient(value: string): ParsedIngredient {
  const ingredientWithUnit = parseIngredientWithUnit(value);
  if (ingredientWithUnit !== null) {
    return ingredientWithUnit;
  }

  const ingredientWithImplicitEachUnit =
    parseIngredientWithImplicitEachUnit(value);
  if (ingredientWithImplicitEachUnit !== null) {
    return ingredientWithImplicitEachUnit;
  }

  return oneEachOf(value);
}

function parseIngredientWithUnit(value: string): ParsedIngredient | null {
  const unitRegex = Array.from(allUnitStringsForParsing)
    .map((unitString) => escapeRegExp(unitString))
    .join("|");

  const result = new RegExp(
    `^(?:([0-9]+)\\s+x\\s+)?(${quantityRegex})\\s*(${unitRegex})\\s+(.+)$`
  ).exec(value);

  if (result === null) {
    return null;
  }

  const [, multiplierString, quantityString, unitString, name] = result;

  const unit = parseUnit(unitString);

  if (unit === null) {
    return null;
  }

  let quantity = parseQuantity(quantityString);
  if (multiplierString) {
    quantity *= parseInt(multiplierString, 10);
  }

  return {
    id: uuidv4(),
    name,
    quantity,
    subrecipeId: null,
    unit: unit.value,
  };
}

function parseIngredientWithImplicitEachUnit(
  value: string
): ParsedIngredient | null {
  const result = new RegExp(`^(${quantityRegex})\\s+(.+)$`).exec(value);

  if (result === null) {
    return null;
  }

  const [, quantityString, name] = result;

  return {
    id: uuidv4(),
    name,
    quantity: parseQuantity(quantityString),
    subrecipeId: null,
    unit: "each",
  };
}

const quantityRegex = /[0-9]+(?:\.[0-9]+)?|1\/2/.source;

function parseQuantity(quantityString: string): number {
  if (quantityString === "1/2") {
    return 0.5;
  } else {
    return parseFloat(quantityString);
  }
}

function oneEachOf(value: string): ParsedIngredient {
  return {
    id: uuidv4(),
    name: value,
    quantity: 1,
    subrecipeId: null,
    unit: "each",
  };
}
