import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer mb-3">
      <span id="ft" className="h6 small px-3">
        &#169; {new Date().getFullYear()} Foodsteps
      </span>
    </div>
  );
}
