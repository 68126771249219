import LightCard from "./LightCard";

const stories = {
  name: "LightCard",
  render: () => (
    <div className="w-25 m-3">
      <LightCard>
        <h1>Title</h1>
        <div>Body with some text and other words.</div>
      </LightCard>
    </div>
  ),
};

export default stories;
