import { IntlProvider as BaseIntlProvider } from "react-intl";
import { useCallbackOne } from "use-memo-one";

import StatusDisplay from "./components/StatusDisplay";
import usePromise from "./components/utils/usePromise";
import { useUserInfoOrNull } from "./data-store/useUserInfo";

interface IntlProviderProps {
  children: React.ReactNode;
}

export function IntlProvider(props: IntlProviderProps) {
  const { children } = props;
  const locale = useLocale();

  return (
    <IntlProviderWithExplicitLocale locale={locale}>
      {children}
    </IntlProviderWithExplicitLocale>
  );
}

interface IntlProviderWithExplicitLocaleProps {
  children: React.ReactNode;
  locale: string;
}

export function IntlProviderWithExplicitLocale(
  props: IntlProviderWithExplicitLocaleProps
) {
  const { children, locale } = props;

  const loadMessages = useCallbackOne(
    () => loadMessagesForLocale(locale),
    [locale]
  );

  const [messagesStatus] = usePromise(loadMessages);

  return (
    <StatusDisplay status={messagesStatus}>
      {(messages) => (
        <BaseIntlProvider
          messages={messages}
          locale={locale}
          defaultLocale={DEFAULT_LOCALE}
        >
          {children}
        </BaseIntlProvider>
      )}
    </StatusDisplay>
  );
}

function useLocale(): string {
  const userInfo = useUserInfoOrNull();
  return userInfo?.locale ?? DEFAULT_LOCALE;
}

const DEFAULT_LOCALE = "en-GB";

function loadMessagesForLocale(locale: string) {
  switch (locale) {
    case "de-DE":
      return import("./lang-compiled/de-DE.json");
    case "en-GB":
      return import("./lang-compiled/en-GB.json");
    case "es-419":
      return import("./lang-compiled/es-419.json");
    case "es-ES":
      return import("./lang-compiled/es-ES.json");
    case "fr-FR":
      return import("./lang-compiled/fr-FR.json");
    case "nl-NL":
      return import("./lang-compiled/nl-NL.json");
    case "TEST":
      return import("./lang-compiled/TEST.json");
    case "zh-CN":
      return import("./lang-compiled/zh-CN.json");
    default:
      return Promise.resolve({});
  }
}
