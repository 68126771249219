import actionBarStories from "./ActionBar.stories";
import comparisonRecipeSelectStories from "./ComparisonRecipeSelect.stories";
import functionalUnitDropdownStories from "./FunctionalUnitDropdown.stories";
import impactCategoryCardStories from "./ImpactCategoryCard.stories";
import impactCategoryToggleStories from "./ImpactCategoryToggle.stories";
import impactComparisonStories from "./ImpactComparison.stories";
import impactRatingExplanationStories from "./ImpactRatingExplanation.stories";
import impactSummaryStories from "./ImpactSummary.stories";
import ingredientImpactChartStories from "./IngredientImpactChart.stories";
import ingredientMatcherStories from "./IngredientMatcher.stories";
import lifeCycleImpactsStories from "./LifeCycleImpacts.stories";
import lifeCycleImpactTableStories from "./LifeCycleImpactTable.stories";
import packagingComponentImpactSectionStories from "./PackagingComponentImpactSection.stories";
import recipePackagingComponentTableStories from "./RecipePackagingComponentTable.stories";
import recipesDropdownStories from "./RecipesDropdown.stories";
import uploadedRecipesTableStories from "./UploadedRecipesTable.stories";
import uploadRecipesPageStories from "./UploadRecipesPage.stories";

const stories = {
  name: "recipes",
  children: [
    actionBarStories,
    comparisonRecipeSelectStories,
    functionalUnitDropdownStories,
    impactCategoryCardStories,
    ingredientImpactChartStories,
    impactCategoryToggleStories,
    impactComparisonStories,
    impactRatingExplanationStories,
    impactSummaryStories,
    ingredientMatcherStories,
    lifeCycleImpactsStories,
    lifeCycleImpactTableStories,
    packagingComponentImpactSectionStories,
    recipePackagingComponentTableStories,
    recipesDropdownStories,
    uploadedRecipesTableStories,
    uploadRecipesPageStories,
  ],
};

export default stories;
