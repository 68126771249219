import gql from "graphql-tag";

import { SystemBoundary } from "../__generated__/globalTypes";
import {
  useOrganization,
  useOrganizationOrNull,
} from "../components/organizations/OrganizationProvider";

export function useAccurateCookingImpacts(): boolean {
  const [organization] = useOrganization();
  return organization.features.accurateCookingImpacts;
}

export function useAccuratePostPreparationStorageImpacts(): boolean {
  const [organization] = useOrganization();
  return organization.features.accuratePostPreparationStorageImpacts;
}

export function useAdditionalImpactCategories(): boolean {
  const landUse = useLandUse();
  const waterUse = useWaterUse();
  return landUse || waterUse;
}

export function useBrandedIngredients(): boolean {
  const [organization] = useOrganization();
  return organization.features.brandedIngredients;
}

export function useCarbonLabels(): boolean {
  const [organization] = useOrganization();
  return organization.features.carbonLabels;
}

export function useCollections(): boolean {
  const [organization] = useOrganization();
  return organization.features.collections;
}

export function useCountryOfOriginSourcing(): boolean {
  const [organization] = useOrganization();
  return organization.features.countryOfOriginSourcing;
}

export function useDashboard(): boolean {
  const [organization] = useOrganization();
  return organization.features.dashboard;
}

export function useDataQualityScore(): boolean {
  const [organization] = useOrganization();
  return organization.features.dataQualityScore;
}

export function useDetailedLifecycleAnalysis(): boolean {
  const [organization] = useOrganization();
  return organization.features.detailedLifeCycleAnalysis;
}

export function useEditProducts(): boolean {
  const [organization] = useOrganization();
  return organization.features.editProducts;
}

export function useExportIngredientImpactsByStage(): boolean {
  const [organization] = useOrganization();
  return organization.features.exportIngredientImpactsByStage;
}

export function useFoodManufacturerOrganization(): boolean {
  const [organization] = useOrganizationOrNull();
  return (
    organization !== null && organization.features.foodManufacturerOrganization
  );
}

export function useIngredientImpacts(): boolean {
  const [organization] = useOrganizationOrNull();
  return organization !== null && organization.features.ingredientImpacts;
}

export function useLandUse(): boolean {
  const [organization] = useOrganization();
  return organization.features.landUse;
}

export function useNewLifeCycleImpactChart(): boolean {
  const [organization] = useOrganization();
  return organization.features.newLifeCycleImpactChart;
}

export function useProductPackaging(): boolean {
  const [organization] = useOrganization();
  return organization.features.productPackaging;
}

export function useRequestImpactAnalysis(): boolean {
  const [organization] = useOrganization();
  return organization.features.requestImpactAnalysis;
}

export function useSubproducts(): boolean {
  const [organization] = useOrganization();
  return organization.features.subproducts;
}

export function useSystemBoundary(): SystemBoundary {
  const [organization] = useOrganization();
  return organization.features.systemBoundary;
}

export function useOrganizationSystemBoundaryIsCradleToGrave(): boolean {
  const [organization] = useOrganization();
  return (
    organization.features.systemBoundary === SystemBoundary.CRADLE_TO_GRAVE
  );
}

export function useTags(): boolean {
  const [organization] = useOrganization();
  return organization?.features.tags;
}

export function useViewSharedProducts(): boolean {
  const [organization] = useOrganization();
  return organization?.features.viewSharedProducts;
}

export function useWaterUse(): boolean {
  const [organization] = useOrganization();
  return organization.features.waterUse;
}

export function organizationFeaturesFragment() {
  return gql`
    fragment useOrganizationFeatures_Organization on Organization {
      features {
        accurateCookingImpacts
        accuratePostPreparationStorageImpacts
        brandedIngredients
        carbonLabels
        collections
        countryOfOriginSourcing
        dashboard
        dataQualityScore
        detailedLifeCycleAnalysis
        editProducts
        exportIngredientImpactsByStage
        foodManufacturerOrganization
        ingredientImpacts
        landUse
        newLifeCycleImpactChart
        productPackaging
        requestImpactAnalysis
        shareProducts
        subproducts
        systemBoundary
        tags
        viewSharedProducts
        waterUse
      }
    }
  `;
}
