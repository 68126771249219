import * as ColourDescriptiveRatingLabels from "../../utils/Vectors/carbon-labels/colour/descriptive-rating";
import * as MonochromeDescriptiveRatingLabels from "../../utils/Vectors/carbon-labels/monochrome/descriptive-rating";
import {
  BaseCarbonLabelProps,
  CarbonLabelSizeOption,
  labelSizeToDimensions,
} from "./CarbonLabel";

export interface DescriptiveRatingLabelProps extends BaseCarbonLabelProps {
  size: CarbonLabelSizeOption;
}

export default function DescriptiveRatingLabel(
  props: DescriptiveRatingLabelProps
) {
  const { className, impactRating, monochrome, size, width } = props;
  const DescriptiveRatingLabels = monochrome
    ? MonochromeDescriptiveRatingLabels
    : ColourDescriptiveRatingLabels;

  const labelDimensions = labelSizeToDimensions("descriptiveRating", size);
  const labelWidth = width ?? labelDimensions.width;
  switch (impactRating) {
    case "VLOW":
      return (
        <DescriptiveRatingLabels.DescriptiveRatingLabelVLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "LOW":
      return (
        <DescriptiveRatingLabels.DescriptiveRatingLabelLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "MEDIUM":
      return (
        <DescriptiveRatingLabels.DescriptiveRatingLabelMedium
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "HIGH":
      return (
        <DescriptiveRatingLabels.DescriptiveRatingLabelHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "VHIGH":
      return (
        <DescriptiveRatingLabels.DescriptiveRatingLabelVHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
  }
}
