import papaparse from "papaparse";

import * as comparators from "./comparators";
import {
  BlankCompulsoryFieldError,
  CannotBeZeroError,
  ParseBooleanError,
  ParseFloatError,
} from "./csv-errors";

export interface CsvData {
  headers: Array<string>;
  body: Array<Row>;
}

export class Row {
  public readonly index: number;
  public readonly values: Array<string>;

  constructor(index: number, values: Array<string>) {
    this.index = index;
    this.values = values;
  }

  isEmpty() {
    return this.values.every((cell) => cell === "");
  }

  rowNumber() {
    return this.index + 1;
  }

  readRaw(header: Header | null): string | null {
    return header === null ? null : this.values[header.index];
  }

  readOptional(header: Header | null): string | null {
    return this.readRaw(header) || null;
  }

  readOptionalNumber(header: Header | null): number | null {
    if (header === null) {
      return null;
    }
    const string = this.readOptional(header);
    if (string === null) {
      return null;
    }
    return parseNumberWithErrorHandling(string, header.name, this.rowNumber());
  }

  readOptionalBoolean(header: Header | null): boolean | null {
    if (header === null) {
      return null;
    }

    const input = this.readOptional(header);

    if (input === null) {
      return null;
    }

    const normalizedInput = input.toLowerCase();

    if (normalizedInput === "yes") {
      return true;
    } else if (normalizedInput === "no") {
      return false;
    } else if (normalizedInput === undefined || normalizedInput === "") {
      return null;
    } else {
      throw new ParseBooleanError(input, header.name, this.index);
    }
  }

  readCompulsory(header: Header): string {
    const value = this.values[header.index];
    if (value === "") {
      throw new BlankCompulsoryFieldError(header.name, this.rowNumber());
    } else {
      return value;
    }
  }

  readCompulsoryNumber(header: Header): number {
    const string = this.readCompulsory(header);
    return parseNumberWithErrorHandling(string, header.name, this.rowNumber());
  }
}

function parseNumberWithErrorHandling(
  input: string,
  columnName: string,
  rowNumber: number
): number {
  const result = Number(input);
  if (isNaN(result)) {
    throw new ParseFloatError(input, columnName, rowNumber);
  } else if (result === 0) {
    throw new CannotBeZeroError(columnName, rowNumber);
  } else {
    return result;
  }
}

export function parse(text: string): CsvData {
  const [headers, ...body] = parseValues(text);
  const trimmedHeaders = headers.map((header) =>
    header.trim().replace(/\s+/g, " ")
  );

  return {
    headers: trimmedHeaders,
    body: body.map((row, bodyRowIndex) => new Row(bodyRowIndex + 1, row)),
  };
}

function parseValues(text: string): Array<Array<string>> {
  const result = papaparse.parse(text);
  if (result.errors.length > 0) {
    throw new Error(`failed to parse CSV: ${result.errors[0].message}`);
  } else {
    return result.data as Array<Array<string>>;
  }
}

export interface Header {
  name: string;
  index: number;
}

export function findHeaderOrNull(
  headers: Array<string>,
  candidates: Array<string>
): Header | null {
  for (const candidate of candidates) {
    const index = headers.findIndex(
      (header) => comparators.stringSensitivityBase(header, candidate) === 0
    );
    if (index !== -1) {
      return { index, name: headers[index] };
    }
  }

  return null;
}

// Export for testing only
export { parseNumberWithErrorHandling as _parseNumberWithErrorHandling };
