import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import { ImpactCategory } from "../../domain/impactCategories";
import { useAdditionalImpactCategories } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import { usePages } from "../pages";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "../recipes/ImpactCategoryToggle";
import SystemBoundaryText from "../recipes/SystemBoundaryText";
import { SecondaryButtonLink } from "../utils/Button";
import { ContactSupport } from "../utils/Vectors";

interface WelcomeBannerProps {
  impactCategory: ImpactCategory;
  onImpactCategoryChange: (ImpactCategory: ImpactCategory) => void;
}

export default function WelcomeBanner(props: WelcomeBannerProps) {
  const { impactCategory, onImpactCategoryChange } = props;

  const [userInfo] = useUserInfo();
  const additionalImpactCategories = useAdditionalImpactCategories();
  const pages = usePages();
  const { trackImpactCategorySet } = useTracking();

  const handleImpactCategoryChange = (impactCategory: ImpactCategory) => {
    onImpactCategoryChange(impactCategory);
    trackImpactCategorySet({
      impactCategory,
      pageName: pages.Dashboard().title,
    });
  };

  return (
    <div className="col">
      <div
        className={classNames("row", "mb-3")}
        style={{
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "end",
        }}
      >
        <h1 className="mb-0">
          <FormattedMessage
            id="components/dashboard/WelcomeBanner:welcome"
            defaultMessage="Welcome back, {name}"
            values={{ name: userInfo.firstName }}
          />
        </h1>
        <SecondaryButtonLink
          to="/contact-support"
          icon={<ContactSupport width="20px" />}
        >
          <FormattedMessage
            id="components/dashboard/WelcomeBanner:ContactUs"
            defaultMessage="Contact"
          />
        </SecondaryButtonLink>
      </div>
      <div
        className={classNames("row", "mb-4")}
        style={{ justifyContent: "space-between", alignContent: "center" }}
      >
        <div
          style={{ justifyContent: "space-between", alignContent: "center" }}
        >
          {additionalImpactCategories && <SystemBoundaryText />}
        </div>
        <div>
          {additionalImpactCategories && (
            <ImpactCategoryToggle
              type={ImpactCategoryToggleType.PAGE}
              onChange={handleImpactCategoryChange}
              selectedImpactCategory={impactCategory}
            />
          )}
        </div>
      </div>
    </div>
  );
}
