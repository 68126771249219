import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { kilogramsUnit, productMassUnits } from "../../../domain/units";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import { combineReadResults } from "../../utils/ReadResult";
import * as WeightEditor from "../../utils/WeightInput";
import * as CoProductsEditor from "./CoProductsEditor";
import * as EconomicValueEditor from "./EconomicValueEditor";
import { LossPercentageEditor } from "./LossPercentageEditor";
import { PhysicalOutputEditor_Recipe } from "./PhysicalOutputEditor.graphql";
import "./PhysicalOutputEditor.css";

export interface Value {
  finalEconomicValue: FloatInput.Value;
  finalProductLoss: FloatInput.Value;
  finalProductWeightQuantity: FloatInput.Value;
  finalProductWeightUnit: string;
  coProducts: CoProductsEditor.Value;
}

export function initialValue(
  recipe: PhysicalOutputEditor_Recipe | undefined
): Value {
  if (recipe === undefined) {
    return {
      finalEconomicValue: FloatInput.initialValue(null),
      finalProductLoss: FloatInput.initialValue(null),
      finalProductWeightQuantity: FloatInput.initialValue(null),
      finalProductWeightUnit: kilogramsUnit.value,
      coProducts: CoProductsEditor.initialValue(null),
    };
  } else {
    return {
      finalEconomicValue: FloatInput.initialValue(
        recipe.unitProcess?.economicValuePerKg ?? null
      ),
      finalProductLoss: FloatInput.initialValue(
        recipe.unitProcess?.finalProductLoss
          ? recipe.unitProcess.finalProductLoss * 100
          : null
      ),
      finalProductWeightQuantity: FloatInput.initialValue(
        recipe.productProcessingMassQuantity
      ),
      finalProductWeightUnit: recipe.productProcessingMassUnit,
      coProducts: CoProductsEditor.initialValue(recipe),
    };
  }
}

export function read(value: Value) {
  const readResults = {
    finalProductLoss: FloatInput.readAllowEmpty({
      value: value.finalProductLoss,
      validationCondition: (value) => value >= 0 && value < 100,
    }),
    finalProductWeightQuantity: FloatInput.readAllowEmpty({
      value: value.finalProductWeightQuantity,
      validationCondition: (value) => value >= 0,
    }),
    finalProductWeightUnit: {
      hasError: false,
      input: value.finalProductWeightUnit,
      value: value.finalProductWeightUnit,
    },
    finalEconomicValue: FloatInput.readAllowEmpty({
      value: finalEconomicValueOrNull(
        value.finalEconomicValue,
        value.coProducts
      ),
      validationCondition: (value) => value >= 0,
    }),
    coProducts: CoProductsEditor.read(value.coProducts),
  };
  return combineReadResults(readResults);
}

const finalEconomicValueOrNull = (
  value: EconomicValueEditor.Value,
  coProducts: CoProductsEditor.Value
): EconomicValueEditor.Value => {
  return coProducts.length ? value : EconomicValueEditor.initialValue(null);
};

interface PhysicalOutputsProps {
  value: Value;
  onChange: (value: Value) => void;
}

export default function PhysicalOutputsEditor(props: PhysicalOutputsProps) {
  const { value, onChange } = props;

  return (
    <div className="PhysicalOutputsEditor_Container">
      <div>
        <label
          className="d-flex mb-0 flex-row"
          htmlFor="processing-physical-outputs"
        >
          <h4>
            <FormattedMessage
              id="components/recipes/ProductEditor/Processing:physicalOutputs"
              defaultMessage="Physical Outputs"
            />
          </h4>
          <HelpModalTooltip
            title={
              <FormattedMessage
                id="components/recipes/ProductEditor/Processing:physicalOutputsTooltipTitle"
                defaultMessage="Physical Outputs"
              />
            }
          >
            <FormattedMessage
              id="components/recipes/ProductEditor/Processing:physicalOutputsTooltipText"
              defaultMessage="
              <p>Outputs could be</p>
              <ul>
                <li><b>The Final Product</b> - the product being assessed, e.g. Cauli Bites</li>
                <li><b>Co-Products</b> - other products that have economic value, e.g. Cauli Rice</li>
              </ul>
              <p>Enter quantities output before any is lost.</p>
              <p>For the final product, add the proportion that is lost (e.g. due to spillage or spoilage).</p>
              <p>For the final product and co-products, enter the economic value per kg. Foodsteps uses the ratio of these numbers to apportion impact, so you are welcome to provide relative values rather than actual monetary values. If you do not provide economic value for a co-product, it will be assumed to have no economic value. If you do not provide economic value for the final product, all co-products will be assumed to have no economic value.</p>
              "
              values={{
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                p: (chunks: React.ReactNode) => <p>{chunks}</p>,
                ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
                li: (chunks: React.ReactNode) => <li>{chunks}</li>,
              }}
            />
          </HelpModalTooltip>
        </label>
        <p className="text-muted mb-0" style={{ maxWidth: "620px" }}>
          <FormattedMessage
            id="components/recipes/ProductEditor/Processing:physicalOutputHelp"
            defaultMessage="Add your outputs and quantities, and for the final product and any co-products, add the economic value and for the final product, the percentage lost."
          />
        </p>
      </div>
      <FinalProductEditor value={value} onChange={onChange} />
      <CoProductsEditor.CoProductsEditor
        value={value.coProducts}
        onChange={(coProducts) => onChange({ ...value, coProducts })}
      />
    </div>
  );
}

interface FinalProductEditorProps {
  value: Value;
  onChange: (value: Value) => void;
}

function FinalProductEditor(props: FinalProductEditorProps) {
  const { value, onChange } = props;

  return (
    <div className="FinalProductEditor">
      <FinalProductEditorHeader />
      <div className="PhysicalOutputsEditor_RowContainer">
        <div className="PhysicalOutputsEditor_Inputs">
          <WeightEditor.WeightInput
            className="PhysicalOutputEditor_WeightInput"
            value={{
              quantity: value.finalProductWeightQuantity,
              unit: value.finalProductWeightUnit,
            }}
            onChange={(weightValue: WeightEditor.Value) =>
              onChange({
                ...value,
                finalProductWeightQuantity: weightValue.quantity,
                finalProductWeightUnit: weightValue.unit,
              })
            }
            units={productMassUnits}
          />
          <div className="PhysicalOutputEditor_TextInput"></div>
          <EconomicValueEditor.EconomicValueEditor
            disabled={value.coProducts.length === 0}
            className="PhysicalOutputEditor_EconomicValueEditor"
            value={finalEconomicValueOrNull(
              value.finalEconomicValue,
              value.coProducts
            )}
            onChange={(finalEconomicValue) =>
              onChange({ ...value, finalEconomicValue })
            }
          />
          <LossPercentageEditor
            className="PhysicalOutputEditor_LossPercentageEditor"
            value={value.finalProductLoss}
            onChange={(finalProductLoss) =>
              onChange({ ...value, finalProductLoss })
            }
          />
        </div>
        <div className="PhysicalOutputEditor_DeleteColumn"></div>
      </div>
    </div>
  );
}

function FinalProductEditorHeader() {
  return (
    <div className="PhysicalOutputsEditor_RowContainer">
      <div className="PhysicalOutputsEditor_Inputs">
        <div className="PhysicalOutputEditor_WeightInput">
          <div className="medium-font">
            <FormattedMessage
              id="components/recipes/ProductEditor/PhysicalOutputEditor:FinalProductEditorHeader/finalProduct"
              defaultMessage="Final Product"
            />
          </div>
        </div>
        <div className="PhysicalOutputEditor_TextInput"></div>
        <div className="PhysicalOutputEditor_EconomicValueEditor PhysicalOutputEditor_CenteredSubheading">
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalOutputEditor:FinalProductEditorHeader/economicValue"
            defaultMessage="Economic Value"
          />
        </div>
        <div className="PhysicalOutputEditor_LossPercentageEditor PhysicalOutputEditor_CenteredSubheading">
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalOutputEditor:FinalProductEditorHeader/percentageLost"
            defaultMessage="Percentage Lost"
          />
        </div>
      </div>
      <div className="PhysicalOutputEditor_DeleteColumn"></div>
    </div>
  );
}

export const fragments = {
  recipe: () => gql`
    fragment PhysicalOutputEditor_Recipe on Recipe {
      id
      productProcessingMassQuantity
      productProcessingMassUnit
      unitProcess {
        economicValuePerKg
        finalProductLoss
      }
      coProducts {
        economicValuePerKg
        name
        massQuantity
        massUnit
      }
    }
  `,
};
