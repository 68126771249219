import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { parseUnit } from "../../domain/units";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import * as csv from "../../util/csv";
import { ParsedIngredient, parseIngredient } from "./ingredient-parser";
import {
  codeColumnDescription,
  collectionColumnDescription,
  ColumnDescription,
  getDefaultColumnName,
  ingredientColumnDescription,
  quantityColumnDescription,
  recipeColumnDescription,
  recipeNotCookedColumnDescription,
  recipeNotStoredColumnDescription,
  servingsColumnDescription,
  subrecipeIdColumnDescription,
  unitColumnDescription,
  weightInGramsColumnDescription,
} from "./recipes-csv-columns";
import {
  ConflictingRecipeDataError,
  DuplicateRecipesConflictingDataError,
  InvalidUnitError,
  MissingColumnError,
  RecipeWithDuplicateNameHasMissingInformationError,
} from "./recipes-csv-errors";

interface ParsedBoolean {
  rawValue: string | null;
  parsedValue: boolean;
}

export interface ParsedRecipe {
  clientId: string;
  code: string | null;
  collectionNames: Array<string> | null;
  ingredients: Array<ParsedIngredient>;
  name: string;
  notCooked: ParsedBoolean;
  notStored: ParsedBoolean;
  rowIndex: number;
  servings: number | null;
  skipUpload?: boolean;
}

export interface ParseRecipesCsvOutput {
  parsedRecipes: Array<ParsedRecipe>;
  servingsCountRequired: boolean;
}

export function parseRecipesCsv(text: string): ParseRecipesCsvOutput {
  if (text === "") {
    return { parsedRecipes: [], servingsCountRequired: false };
  }

  const { headers, body } = csv.parse(text);
  const format = detectFormat(headers);

  const servingsCountRequired = format.servingsHeader === null;

  const rows = body.filter((row) => !row.isEmpty());

  if (format.type === "rowPerRecipe") {
    const parsedRecipes = rows.map((row, rowIndex) => ({
      clientId: uuidv4(),
      code: row.readOptional(format.codeHeader),
      collectionNames: readCollectionNames(row, format.collectionNameHeader),
      ingredients: mapNotNull(format.ingredientHeaderIndices, (headerIndex) => {
        const ingredientString = row.values[headerIndex];
        if (ingredientString === "") {
          return null;
        } else {
          return parseIngredient(ingredientString);
        }
      }),
      name: row.readCompulsory(format.recipeNameHeader),
      notCooked: readRecipeNotCooked(row, format.recipeNotCookedHeader),
      notStored: readRecipeNotStored(row, format.recipeNotStoredHeader),
      rowIndex,
      servings: readServings(row, format.servingsHeader),
    }));
    return { parsedRecipes, servingsCountRequired };
  } else if (format.type === "rowPerIngredient") {
    const rowsByRecipeIdentifier = new Map<string, ParsedRecipe>();
    const rowsByRecipeName = new Map<string, Array<ParsedRecipe>>();

    rows.forEach((row, rowIndex) => {
      const recipeName = row.readCompulsory(format.recipeNameHeader);
      const collectionNames = readCollectionNames(
        row,
        format.collectionNameHeader
      );

      const recipeIdentifier = createRecipeIdentifier(
        recipeName,
        collectionNames
      );

      let recipe = rowsByRecipeIdentifier.get(recipeIdentifier);
      if (recipe === undefined) {
        recipe = {
          clientId: uuidv4(),
          name: recipeName,
          code: row.readOptional(format.codeHeader),
          collectionNames,
          ingredients: [],
          notCooked: readRecipeNotCooked(row, format.recipeNotCookedHeader),
          notStored: readRecipeNotStored(row, format.recipeNotStoredHeader),
          rowIndex,
          servings: readServings(row, format.servingsHeader),
        };
        rowsByRecipeIdentifier.set(recipeIdentifier, recipe);

        if (!rowsByRecipeName.has(recipeName)) {
          rowsByRecipeName.set(recipeName, [recipe]);
        } else {
          const duplicateNameRecipes = rowsByRecipeName.get(
            recipeName
          ) as ParsedRecipe[];
          rowsByRecipeName.set(recipeName, [...duplicateNameRecipes, recipe]);
          validateDuplicateRecipesHaveDistinctCodes(
            [...duplicateNameRecipes, recipe],
            format
          );
        }
      } else {
        validateRecipeRowIsConsistentWithRecipe(row, recipe, format);
      }

      const ingredientName = row.readCompulsory(format.ingredientNameHeader);
      const ingredientSubrecipeId = row.readOptionalNumber(
        format.subrecipeIdHeader
      );

      let ingredientQuantity: number;
      let ingredientQuantityUnit: string;

      if (format.ingredientQuantityFormat.type === "weightInGrams") {
        ingredientQuantity = row.readCompulsoryNumber(
          format.ingredientQuantityFormat.weightInGramsHeader
        );
        ingredientQuantityUnit = "g";
      } else if (format.ingredientQuantityFormat.type === "quantityWithUnit") {
        ingredientQuantity = row.readCompulsoryNumber(
          format.ingredientQuantityFormat.quantityHeader
        );
        ingredientQuantityUnit = readUnitValue(
          row,
          format.ingredientQuantityFormat.quantityUnitHeader,
          recipeName
        );
      } else {
        assertNever(
          format.ingredientQuantityFormat,
          "unhandled ingredient quantity format"
        );
      }

      const ingredient = {
        id: uuidv4(),
        name: ingredientName,
        quantity: ingredientQuantity,
        subrecipeId: ingredientSubrecipeId,
        unit: ingredientQuantityUnit,
      };
      recipe.ingredients.push(ingredient);
    });

    const parsedRecipes = Array.from(rowsByRecipeIdentifier.values());
    return { parsedRecipes, servingsCountRequired };
  } else {
    return assertNever(format, "unhandled format type");
  }
}

function readUnitValue(
  row: csv.Row,
  header: csv.Header,
  recipeName: string
): string {
  const unitInput = row.readCompulsory(header);
  const unit = parseUnit(unitInput);
  if (unit === null) {
    throw new InvalidUnitError(
      unitInput,
      header.name,
      recipeName,
      row.rowNumber()
    );
  } else {
    return unit.value;
  }
}

function readCollectionNames(
  row: csv.Row,
  header: csv.Header | null
): Array<string> | null {
  if (header === null) {
    return null;
  }
  const collectionNames = row.readOptional(header);
  if (collectionNames === null) {
    return null;
  }
  const collectionNamesArray = collectionNames.match(/(?:\\,|[^,])+/g); // Defines a word as a sequence of non-commas or escaped commas
  return collectionNamesArray!.map((collectionName) =>
    collectionName.replace(/\\/g, "")
  );
}

function readRecipeNotCooked(
  row: csv.Row,
  header: csv.Header | null
): ParsedBoolean {
  return {
    rawValue: row.readRaw(header),
    parsedValue: row.readOptionalBoolean(header) ?? false,
  };
}

function readRecipeNotStored(
  row: csv.Row,
  header: csv.Header | null
): ParsedBoolean {
  return {
    rawValue: row.readRaw(header),
    parsedValue: row.readOptionalBoolean(header) ?? false,
  };
}

function readServings(row: csv.Row, header: csv.Header | null): number | null {
  if (header === null) {
    return null;
  } else {
    return row.readCompulsoryNumber(header);
  }
}

type Format =
  | {
      type: "rowPerRecipe";
      codeHeader: csv.Header | null;
      collectionNameHeader: csv.Header | null;
      ingredientHeaderIndices: Array<number>;
      recipeNameHeader: csv.Header;
      recipeNotCookedHeader: csv.Header | null;
      recipeNotStoredHeader: csv.Header | null;
      servingsHeader: csv.Header | null;
    }
  | {
      type: "rowPerIngredient";
      codeHeader: csv.Header | null;
      collectionNameHeader: csv.Header | null;
      ingredientNameHeader: csv.Header;
      ingredientQuantityFormat: RowPerIngredientIngredientQuantityFormat;
      recipeNameHeader: csv.Header;
      recipeNotCookedHeader: csv.Header | null;
      recipeNotStoredHeader: csv.Header | null;
      servingsHeader: csv.Header | null;
      subrecipeIdHeader: csv.Header | null;
    };

type RowPerIngredientIngredientQuantityFormat =
  | { type: "weightInGrams"; weightInGramsHeader: csv.Header }
  | {
      type: "quantityWithUnit";
      quantityHeader: csv.Header;
      quantityUnitHeader: csv.Header;
    };

function detectFormat(headers: Array<string>): Format {
  const recipeNameHeader = findRecipeNameHeader(headers);
  const collectionNameHeader = findCollectionHeaderOrNull(headers);
  const ingredientNameHeader = findIngredientNameHeaderOrNull(headers);
  const recipeNotCookedHeader = findRecipeNotCookedHeaderOrNull(headers);
  const recipeNotStoredHeader = findRecipeNotStoredHeaderOrNull(headers);
  const servingsHeader = findServingsHeaderOrNull(headers);
  const codeHeader = findCodeHeaderOrNull(headers);
  const subrecipeIdHeader = findSubrecipeIdHeaderOrNull(headers);

  if (ingredientNameHeader === null) {
    const ingredientHeaderIndices = mapNotNull(headers, (header, headerIndex) =>
      /^ingredients[0-9]+$/.test(header) ? headerIndex : null
    );

    return {
      type: "rowPerRecipe",
      codeHeader,
      collectionNameHeader,
      ingredientHeaderIndices,
      recipeNameHeader,
      recipeNotCookedHeader,
      recipeNotStoredHeader,
      servingsHeader,
    };
  } else {
    const weightInGramsHeader = findWeightInGramsHeaderOrNull(headers);

    const ingredientQuantityFormat =
      weightInGramsHeader === null
        ? {
            type: "quantityWithUnit" as const,
            quantityHeader: findIngredientQuantityHeader(headers),
            quantityUnitHeader: findIngredientQuantityUnitHeader(headers),
          }
        : {
            type: "weightInGrams" as const,
            weightInGramsHeader,
          };

    return {
      type: "rowPerIngredient",
      codeHeader,
      collectionNameHeader,
      ingredientNameHeader,
      ingredientQuantityFormat,
      recipeNameHeader,
      recipeNotCookedHeader,
      recipeNotStoredHeader,
      servingsHeader,
      subrecipeIdHeader,
    };
  }
}

function findCollectionHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    collectionColumnDescription.columnNameCandidates
  );
}

function findIngredientNameHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    ingredientColumnDescription.columnNameCandidates
  );
}

function findIngredientQuantityHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, quantityColumnDescription);
}

function findIngredientQuantityUnitHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, unitColumnDescription);
}

function findRecipeNameHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, recipeColumnDescription);
}

function findCompulsoryHeader(
  headers: Array<string>,
  columnDescription: ColumnDescription
): csv.Header {
  const header = csv.findHeaderOrNull(
    headers,
    columnDescription.columnNameCandidates
  );
  if (header === null) {
    throw new MissingColumnError(getDefaultColumnName(columnDescription));
  } else {
    return header;
  }
}

function findServingsHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    servingsColumnDescription.columnNameCandidates
  );
}

function findWeightInGramsHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    weightInGramsColumnDescription.columnNameCandidates
  );
}

function findRecipeNotCookedHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    recipeNotCookedColumnDescription.columnNameCandidates
  );
}

function findRecipeNotStoredHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    recipeNotStoredColumnDescription.columnNameCandidates
  );
}

function findCodeHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    codeColumnDescription.columnNameCandidates
  );
}

function findSubrecipeIdHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    subrecipeIdColumnDescription.columnNameCandidates
  );
}

function createRecipeIdentifier(
  recipeName: string,
  collectionNames: Array<string> | null
) {
  return JSON.stringify([recipeName, collectionNames?.join(",") ?? ""]);
}

function validateDuplicateRecipesHaveDistinctCodes(
  recipesWithDuplicateNames: Array<ParsedRecipe>,
  format: Format
) {
  for (const currentRecipe of recipesWithDuplicateNames) {
    if (currentRecipe.code === null || currentRecipe.code === "") {
      throw new RecipeWithDuplicateNameHasMissingInformationError(
        format.codeHeader?.name ?? "Code",
        currentRecipe.name,
        currentRecipe.rowIndex
      );
    }
    const recipeWithIdenticalCode = recipesWithDuplicateNames.find(
      (recipe) =>
        recipe.code === currentRecipe.code &&
        recipe.rowIndex !== currentRecipe.rowIndex
    );
    if (recipeWithIdenticalCode !== undefined) {
      throw new DuplicateRecipesConflictingDataError(
        format.codeHeader?.name ?? "Code",
        currentRecipe.name,
        currentRecipe.rowIndex,
        recipeWithIdenticalCode.rowIndex,
        currentRecipe.code
      );
    }
  }
}

function validateRecipeRowIsConsistentWithRecipe(
  row: csv.Row,
  recipe: ParsedRecipe,
  format: Format
) {
  if (format.collectionNameHeader !== null) {
    // Ensure that all rows in a recipe have the same collection name (or are all null).
    const collectionNames = readCollectionNames(
      row,
      format.collectionNameHeader
    );
    if (!isEqual(collectionNames, recipe.collectionNames)) {
      throw new ConflictingRecipeDataError(
        recipe.collectionNames,
        collectionNames,
        format.collectionNameHeader.name,
        recipe.name
      );
    }
  }

  if (format.servingsHeader !== null) {
    // Ensure that all rows in a recipe have the same servings value.
    const servings = readServings(row, format.servingsHeader);
    if (servings !== recipe.servings) {
      throw new ConflictingRecipeDataError(
        recipe.servings,
        servings,
        format.servingsHeader.name,
        recipe.name
      );
    }
  }

  if (format.recipeNotCookedHeader !== null) {
    const recipeNotCooked = readRecipeNotCooked(
      row,
      format.recipeNotCookedHeader
    );
    if (recipeNotCooked.parsedValue !== recipe.notCooked.parsedValue) {
      throw new ConflictingRecipeDataError(
        recipe.notCooked.rawValue,
        recipeNotCooked.rawValue,
        format.recipeNotCookedHeader.name,
        recipe.name
      );
    }
  }

  if (format.recipeNotStoredHeader !== null) {
    const recipeNotStored = readRecipeNotStored(
      row,
      format.recipeNotStoredHeader
    );
    if (recipeNotStored.parsedValue !== recipe.notStored.parsedValue) {
      throw new ConflictingRecipeDataError(
        recipe.notStored.rawValue,
        recipeNotStored.rawValue,
        format.recipeNotStoredHeader.name,
        recipe.name
      );
    }
  }

  if (format.codeHeader !== null) {
    // Ensure that all rows in a recipe have the same code name (or are all null).
    const code = row.readOptional(format.codeHeader);
    if (code !== recipe.code) {
      throw new ConflictingRecipeDataError(
        recipe.code,
        code,
        format.codeHeader.name,
        recipe.name
      );
    }
  }
}

// Exported for testing only
export {
  findCodeHeaderOrNull as _findCodeHeaderOrNull,
  findCollectionHeaderOrNull as _findCollectionHeaderOrNull,
  findIngredientNameHeaderOrNull as _findIngredientNameHeaderOrNull,
  findIngredientQuantityHeader as _findIngredientQuantityHeader,
  findIngredientQuantityUnitHeader as _findIngredientQuantityUnitHeader,
  findRecipeNameHeader as _findRecipeNameHeader,
  findServingsHeaderOrNull as _findServingsHeaderOrNull,
  findSubrecipeIdHeaderOrNull as _findSubrecipeIdHeaderOrNull,
  findWeightInGramsHeaderOrNull as _findWeightInGramsHeaderOrNull,
};
