import React, { useContext } from "react";

import { CalculationTableRow } from "../components/calculation-table/CalculationTable";
import FetchGraphQL from "../components/graphql/FetchGraphQL";
import AvailableIngredient from "../domain/AvailableIngredient";

export interface ChangePasswordOptions {
  userId: number;
  username: string;
  oldPassword: string;
  newPassword: string;
}

export interface MaintenanceWindow {
  start: Date;
  end: Date;
  isActive: boolean;
}

export interface SupportedRegion {
  id: number;
  name: string;
}

export interface DataStore {
  fetchCalculationTable: (
    subjectId: string,
    subjectType: string,
    otherParams: URLSearchParams
  ) => Promise<CalculationTableRow>;

  fetchGraphQL: FetchGraphQL;

  fetchAvailableIngredients: (
    organizationId: string | null
  ) => Promise<Array<AvailableIngredient>>;

  fetchMaintenanceWindows: () => Promise<Array<MaintenanceWindow>>;

  fetchMethodologySummaryUrl: () => Promise<string | null>;

  fetchPublicOrganizationData: (
    organizationId: string
  ) => Promise<{ organizationName: string }>;

  changePassword: (args: ChangePasswordOptions) => Promise<void>;

  fetchSupportedRegions: () => Promise<Array<SupportedRegion>>;

  key: unknown;
}

const DataStoreContext = React.createContext<DataStore | null>(null);

export function useDataStore(): DataStore {
  const dataStore = useContext(DataStoreContext);

  if (dataStore === null) {
    throw new Error("missing provider for DataStoreContext");
  } else {
    return dataStore;
  }
}

export const DataStoreProvider = DataStoreContext.Provider;
