import { gql } from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useCollections from "../../data-store/useCollections";
import { useTracking } from "../../tracking";
import StatusDisplay from "../StatusDisplay";
import { SecondaryButton } from "../utils/Button";
import DeleteModal from "../utils/DeleteModal";
import TooltipOverlay from "../utils/TooltipOverlay";
import useId from "../utils/useId";
import { TagManagement } from "../utils/Vectors";
import { ManageTagsButton_Collection as Collection } from "./ManageTagsButton.graphql";
import ManageTagsModal from "./ManageTagsModal";

import "./ManageTagsButton.css";

export default function ManageTagsButton(props: {
  onCollectionDeleted: (id: number) => void;
  withText: boolean;
}) {
  const { onCollectionDeleted, withText } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const intl = useIntl();
  const tooltipId = useId();
  const { trackManageTagsStarted, trackTagDeleted } = useTracking();

  const [deletingCollection, setDeletingCollection] =
    useState<null | Collection>(null);

  const [
    collectionsStatus,
    ,
    addTag,
    deleteCollection,
    updateRecipeCollection,
  ] = useCollections();

  const handleDeleteClicked = (collection: Collection) => {
    setDeletingCollection(collection);
  };

  const handleConfirmDelete = async (deletingCollection: Collection) => {
    await deleteCollection({
      variables: { collectionId: deletingCollection.id },
    });
    trackTagDeleted({
      tagName: deletingCollection.name,
      tagId: deletingCollection.id,
    });
    setDeletingCollection(null);
    onCollectionDeleted(deletingCollection.id);
  };

  const handleHideDeleteModal = () => {
    setDeletingCollection(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    trackManageTagsStarted();
    e.currentTarget.blur();
    setShowModal(true);
  };

  const button = (
    <SecondaryButton
      className="ManageTagsButton"
      onClick={(e) => handleClick(e)}
    >
      <TagManagement width={20} />
      {withText && (
        <FormattedMessage
          id="components/tags/ManageTagsButton:buttonText"
          defaultMessage="Manage tags"
        />
      )}
    </SecondaryButton>
  );

  return (
    <>
      {withText ? (
        button
      ) : (
        <TooltipOverlay
          id={tooltipId}
          placement="top"
          overlay={
            <FormattedMessage
              id="components/tags/ManageTagsButton:tooltipText"
              defaultMessage="Manage tags"
            />
          }
        >
          {button}
        </TooltipOverlay>
      )}
      <StatusDisplay status={collectionsStatus}>
        {(collections) => (
          <ManageTagsModal
            addTag={addTag}
            collections={collections}
            onClose={() => setShowModal(false)}
            show={showModal}
            onDeleteTag={handleDeleteClicked}
            updateRecipeCollection={updateRecipeCollection}
          />
        )}
      </StatusDisplay>
      {deletingCollection && (
        <DeleteModal
          message={
            <FormattedMessage
              id="components/tags/ManageTagsButton:deleteModalMessage"
              defaultMessage="Remove <b>{tagName}</b> from all of its products and permanently delete the tag?"
              values={{
                tagName: deletingCollection.name,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          }
          name={deletingCollection.name}
          onDelete={() => handleConfirmDelete(deletingCollection)}
          onHide={handleHideDeleteModal}
          show
          title={intl.formatMessage({
            id: "components/tags/ManageTagsModal:deleteTag",
            defaultMessage: "Delete Tag?",
          })}
        />
      )}
    </>
  );
}

ManageTagsButton.fragments = {
  collection: gql`
    fragment ManageTagsButton_Collection on RecipeCollection {
      ...ManageTagsModal_Collection
    }

    ${ManageTagsModal.fragments.collection}
  `,
};
