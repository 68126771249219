import ImpactComparison from "./ImpactComparison";

const stories = {
  name: "ImpactComparison",
  render: () => (
    <div className="m-3">
      <ImpactComparison
        impact={{
          effects: {
            ghgPerKg: 1,
            ghgPerRecipe: 1,
            ghgPerRootRecipeServing: 1,
            landUsePerKg: 0.5,
            landUsePerRecipe: 0.5,
            landUsePerRootRecipeServing: 0.5,
            waterUsePerKg: 2.1,
            waterUsePerRootRecipeServing: 2.1,
          },
          unavailableLandUse: false,
          unavailableWaterUse: false,
        }}
        recipe={{ id: 1, name: "Like Nuggets" }}
        includePackaging={true}
      />
    </div>
  ),
};

export default stories;
