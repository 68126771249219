import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import {
  ImpactCalculatorErrorKind,
  ImpactRating,
} from "../../__generated__/globalTypes";
import { ImpactCategory } from "../../domain/impactCategories";
import TooltipOverlay from "../utils/TooltipOverlay";
import { Exclamation } from "../utils/Vectors";
import { RecipeStatus_RecipeImpact as RecipeImpact } from "./RecipeStatus.graphql";
import * as useRecipeImpactIsPending from "./useRecipeImpactIsPending";
import * as useRecipeImpactIsUnavailable from "./useRecipeImpactIsUnavailable";

export type RecipeStatus =
  | { type: "hasImpactRating"; impactRating: ImpactRating }
  | { type: "internalError" }
  | { type: "pending" }
  | { type: "noImpactRating" }
  | { type: "requiresClientAttention" }
  | { type: "unavailable" };

export function useRecipeStatus(
  impact: RecipeImpact,
  impactCategory: ImpactCategory
): RecipeStatus {
  const hasInternalError = impact.errors.some(
    (error) => error.kind === ImpactCalculatorErrorKind.INTERNAL_ERROR
  );
  const isPending = useRecipeImpactIsPending.useRecipeImpactIsPending(impact);
  const isUnavailable =
    useRecipeImpactIsUnavailable.useRecipeImpactIsUnavailable(
      impact,
      impactCategory
    );

  if (hasInternalError) {
    return { type: "internalError" };
  } else if (isPending) {
    return { type: "pending" };
  } else if (impact.requiresClientAttention) {
    return { type: "requiresClientAttention" };
  } else if (isUnavailable) {
    return { type: "unavailable" };
  } else if (impact.impactRating === null) {
    return { type: "noImpactRating" };
  } else {
    return { type: "hasImpactRating", impactRating: impact.impactRating };
  }
}

export const fragments = {
  recipeImpact: gql`
    fragment RecipeStatus_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRecipe
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRecipe
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      errors {
        kind
      }
      impactRating
      requiresClientAttention
      ...useRecipeImpactIsPending_RecipeImpact
      ...useRecipeImpactIsUnavailable_RecipeImpact
    }
    ${useRecipeImpactIsPending.fragments.recipeImpact}
    ${useRecipeImpactIsUnavailable.fragments.recipeImpact}
  `,
};

export function InternalErrorIcon() {
  return (
    <TooltipOverlay
      overlay={
        <FormattedMessage
          defaultMessage="Error encountered."
          id="components/recipes/RecipeStatus:internalErrorMessage"
        />
      }
      id="components/recipes/RecipeStatus:internalErrorTooltip"
    >
      <Exclamation fill="var(--danger)" width={16} />
    </TooltipOverlay>
  );
}
