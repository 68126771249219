const PLATFORM_URI: string = (window as any).FOODSTEPS_PLATFORM_URI;

export interface HttpClient {
  request: (request: HttpRequest) => Promise<HttpResponse>;
  key: unknown;
}

export type HttpMethod = "DELETE" | "GET" | "POST";

interface HttpRequest {
  body?: unknown;
  method: HttpMethod;
  path: string;
}

export interface HttpResponse {
  blob: () => Promise<Blob>;
  json: () => Promise<any>;
  status: number;
  statusText: string;
}

export function createAuthenticatedHttpClient({
  getAccessToken,
  impersonatedUserId,
  key,
}: {
  getAccessToken: () => Promise<string>;
  impersonatedUserId: number | null;
  key: unknown;
}): HttpClient {
  return createHttpClient({
    getAccessToken,
    impersonatedUserId,
    key: ["authenticated", key],
  });
}

export function createUnauthenticatedHttpClient(): HttpClient {
  return createHttpClient({
    getAccessToken: null,
    impersonatedUserId: null,
    key: ["unauthenticated"],
  });
}

function createHttpClient({
  getAccessToken,
  impersonatedUserId,
  key,
}: {
  getAccessToken: null | (() => Promise<string>);
  impersonatedUserId: number | null;
  key: unknown;
}): HttpClient {
  return {
    request: async (request: HttpRequest): Promise<HttpResponse> => {
      const headers = new Headers();

      if (getAccessToken !== null) {
        const token = await getAccessToken();
        headers.append("Authorization", `Bearer ${token}`);
      }

      if (impersonatedUserId !== null) {
        headers.append("X-Impersonate-User-Id", impersonatedUserId.toString());
      }

      if (request.body !== undefined) {
        headers.append("Content-Type", "application/json");
      }

      if (request.path.startsWith("/")) {
        // This fixes requests to //api which possibly causes CORS errors
        request.path = request.path.slice(1);
      }

      const response = await fetch(PLATFORM_URI + request.path, {
        body: request.body === undefined ? null : JSON.stringify(request.body),
        headers,
        method: request.method,
        /*
         * All browsers but Safari seem to happily handle forwarding the Authorization
         * header when the server responds with a redirect due to the request omitting
         * a trailing slash from the endpoint. Safari, on the other hand, does not carry
         * through the header on redirect and thus all requests were getting a 401 response
         * despite the fact the original request contained the valid authorization header.
         *
         * We therefore error on redirect to ensure that we're using the correct URL
         * in the first place.
         *
         * Some discussion can be found here: https://stackoverflow.com/questions/35684933/https-requests-with-authorization-not-working-via-safari/47137013
         */
        redirect: "error",
        credentials: "include",
      });

      return {
        blob: () => response.blob(),
        json: () => response.json(),
        status: response.status,
        statusText: response.statusText,
      };
    },

    key,
  };
}
