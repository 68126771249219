import "./LightCard.scss";
import classNames from "classnames";

interface LightCardProps {
  children: React.ReactNode;
  className?: string;
}

export default function LightCard(props: LightCardProps) {
  const { children, className = "" } = props;

  return <div className={classNames(className, "LightCard")}>{children}</div>;
}
