import { EffectType } from "../../../__generated__/globalTypes";
import { ImpactRating } from "../../../domain/impactRatings";
import assertNever from "../../../util/assertNever";
import { convertSvgToBase64Png } from "../../../util/convertSvgToPng";
import * as labelConstants from "../../utils/Vectors/carbon-labels/constants";
import DescriptiveImpactAndScaleLabel, {
  DescriptiveImpactAndScaleLabelProps,
} from "./DescriptiveImpactAndScaleLabel";
import DescriptiveRatingLabel, {
  DescriptiveRatingLabelProps,
} from "./DescriptiveRatingLabel";
import DescriptiveRatingScaleLabel, {
  DescriptiveRatingScaleLabelProps,
} from "./DescriptiveRatingScaleLabel";
import LetterRatingLabel, { LetterRatingLabelProps } from "./LetterRatingLabel";
import RatingScaleLabel, { RatingScaleLabelProps } from "./RatingScaleLabel";
import TitledRatingScaleLabel, {
  TitledRatingScaleLabelProps,
} from "./TitledRatingScaleLabel";

export type CarbonLabelType =
  | EffectTypeDependentCarbonLabelType
  | SizeDependentCarbonLabelType
  | SizeIndependentCarbonLabelType;

export type SizeDependentCarbonLabelType =
  | "descriptiveRating"
  | "letterRating"
  | "ratingScale";
export type CarbonLabelSizeOption = "small" | "medium" | "large" | "x-large";

type EffectTypeDependentCarbonLabelType = "descriptiveImpactAndScale";

type SizeIndependentCarbonLabelType =
  | "descriptiveRatingScale"
  | "titledRatingScale";

export type LabelColourSetting = "colour" | "monochrome";

export interface BaseCarbonLabelProps {
  className?: string;
  impactRating: ImpactRating;
  monochrome?: boolean;
  width?: number;
}

export type CarbonLabelProps =
  | ({
      type: "descriptiveImpactAndScale";
    } & DescriptiveImpactAndScaleLabelProps)
  | ({ type: "descriptiveRating" } & DescriptiveRatingLabelProps)
  | ({ type: "descriptiveRatingScale" } & DescriptiveRatingScaleLabelProps)
  | ({ type: "letterRating" } & LetterRatingLabelProps)
  | ({ type: "ratingScale" } & RatingScaleLabelProps)
  | ({ type: "titledRatingScale" } & TitledRatingScaleLabelProps);

export default function CarbonLabel(props: CarbonLabelProps) {
  const { className, impactRating, monochrome = false, type, width } = props;
  switch (type) {
    case "descriptiveImpactAndScale":
      return (
        <DescriptiveImpactAndScaleLabel
          className={className}
          effectType={props.effectType}
          impactMagnitude={props.impactMagnitude}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
    case "descriptiveRating":
      return (
        <DescriptiveRatingLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "descriptiveRatingScale":
      return (
        <DescriptiveRatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
    case "letterRating":
      return (
        <LetterRatingLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "ratingScale":
      return (
        <RatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "titledRatingScale":
      return (
        <TitledRatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
  }
}

export async function generateCarbonLabelPngBase64(props: CarbonLabelProps) {
  const { type } = props;
  let dimensions;

  if (type === "descriptiveImpactAndScale") {
    dimensions = labelEffectTypeToDimensions(props.effectType);
  } else if (
    type === "descriptiveRatingScale" ||
    type === "titledRatingScale"
  ) {
    dimensions = labelTypeToDimensions(type);
  } else if (
    type === "descriptiveRating" ||
    type === "letterRating" ||
    type === "ratingScale"
  ) {
    dimensions = labelSizeToDimensions(type, props.size);
  } else {
    assertNever(type, "Invalid label type");
  }

  const carbonLabelSvg = <CarbonLabel {...props} />;

  return await convertSvgToBase64Png({
    height: dimensions.height,
    svgElement: carbonLabelSvg,
    width: dimensions.width,
  });
}

interface LabelDimensions {
  height: number;
  strokeWidth?: number;
  width: number;
}

export function labelSizeToDimensions(
  labelType: SizeDependentCarbonLabelType,
  size: CarbonLabelSizeOption
): LabelDimensions {
  let baseWidth: number;
  let baseHeight: number;
  if (labelType === "descriptiveRating") {
    baseWidth = labelConstants.DESCRIPTIVE_RATING_BASE_SVG_WIDTH_PIXELS;
    baseHeight =
      labelConstants.DESCRIPTIVE_RATING_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else if (labelType === "letterRating") {
    baseWidth = labelConstants.LETTER_RATING_BASE_SVG_WIDTH_PIXELS;
    baseHeight = labelConstants.LETTER_RATING_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else if (labelType === "ratingScale") {
    baseWidth = labelConstants.RATING_SCALE_BASE_SVG_WIDTH_PIXELS;
    baseHeight = labelConstants.RATING_SCALE_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else {
    assertNever(labelType, "Invalid label type");
  }
  switch (size) {
    case "small":
      return { height: baseHeight, strokeWidth: 2, width: baseWidth };
    case "medium":
      return {
        height: labelConstants.MEDIUM_SCALE_FACTOR * baseHeight,
        strokeWidth: 1,
        width: labelConstants.MEDIUM_SCALE_FACTOR * baseWidth,
      };
    case "large":
      return {
        height: labelConstants.LARGE_SCALE_FACTOR * baseHeight,
        strokeWidth: 1,
        width: labelConstants.LARGE_SCALE_FACTOR * baseWidth,
      };
    case "x-large":
      return {
        height: labelConstants.X_LARGE_SCALE_FACTOR * baseHeight,
        strokeWidth: 0.75,
        width: labelConstants.X_LARGE_SCALE_FACTOR * baseWidth,
      };
  }
}

function labelTypeToDimensions(
  labelType: SizeIndependentCarbonLabelType
): LabelDimensions {
  if (labelType === "descriptiveRatingScale") {
    return {
      height:
        labelConstants.DESCRIPTIVE_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
      width: labelConstants.DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
    };
  } else if (labelType === "titledRatingScale") {
    return {
      height:
        labelConstants.TITLED_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
      width: labelConstants.TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
    };
  } else {
    assertNever(labelType, "Invalid label type");
  }
}

function labelEffectTypeToDimensions(effectType: EffectType): LabelDimensions {
  switch (effectType) {
    case EffectType.GHG_PER_KG:
      return {
        height:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_HEIGHT_TO_WIDTH_RATIO *
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
        width:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
      };
    case EffectType.GHG_PER_SERVING:
      return {
        height:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_HEIGHT_TO_WIDTH_RATIO *
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
        width:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
      };
  }
}
