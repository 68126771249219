import compact from "lodash/compact";
import { FormattedMessage } from "react-intl";

import { useCarbonLabels } from "../../services/useOrganizationFeatures";
import { RecipePageName } from "../../tracking";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { Generate } from "../utils/Vectors";
import RecipesDropdown from "./RecipesDropdown";
import useExportCarbonLabelsDropdownItem from "./useExportCarbonLabelsDropdownItem";
import useExportRecipeImpactsCsvDropdownItem from "./useExportRecipeImpactsCsvDropdownItem";

interface ExportRecipesDropdownProps {
  disabled: boolean;
  pageName: RecipePageName;
}

export default function ExportRecipesDropdown(
  props: ExportRecipesDropdownProps
) {
  const { disabled, pageName } = props;
  const [organizationId] = useOrganizationId();
  const hasFeatureCarbonLabels = useCarbonLabels();

  const recipeFilter = { ownerOrganizations: { id: organizationId } };

  const exportCarbonLabelsDropdownItem = useExportCarbonLabelsDropdownItem({
    recipeFilter,
    pageName,
  });

  const exportRecipeImpactsCsvDropdownItem =
    useExportRecipeImpactsCsvDropdownItem({
      pageName,
      recipeFilter,
    });

  return (
    <RecipesDropdown
      disabled={disabled}
      Icon={Generate}
      items={compact([
        hasFeatureCarbonLabels && exportCarbonLabelsDropdownItem,
        exportRecipeImpactsCsvDropdownItem,
      ])}
      title={
        <FormattedMessage
          id="components/recipes/ExportRecipesDropdown:export"
          defaultMessage="Export"
        />
      }
      variant="secondary"
    />
  );
}
