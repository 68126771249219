import {
  Chart as Chartjs,
  ChartConfiguration,
  ChartDatasetProperties,
  TooltipModel,
} from "chart.js";
import { IntlShape, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import EffectType from "../../domain/EffectType";
import Chart from "./Chart";
import { infoCol, foodstepsTurquoiseCol, lightCol } from "./colors";

Chartjs.defaults.color = infoCol;

interface BarChartDataset extends ChartDatasetProperties<"bar", Array<number>> {
  labels: Array<string>;
}

export interface BarChartData {
  datasets: Array<BarChartDataset>;
  labels: Array<string>;
}

interface BarChartProps {
  chartData: BarChartData;
  effectType: EffectType;
  externalTooltipHandler?: (context: {
    chart: Chartjs;
    tooltip: TooltipModel<"bar">;
  }) => void;
  lifeCycle?: boolean;
  tooltipsEnabled?: boolean;
}

const createChartConfig = (
  intl: IntlShape,
  props: BarChartProps
): ChartConfiguration<"bar"> => {
  const {
    chartData,
    effectType,
    externalTooltipHandler,
    tooltipsEnabled = false,
    lifeCycle = false,
  } = props;

  return {
    type: "bar",
    data: {
      labels: chartData.labels,
      datasets: chartData.datasets,
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          labels: {
            stage: {
              anchor: "end",
              align: "top",
              formatter: lifeCycle
                ? function (_, { dataIndex, dataset }) {
                    const label = (dataset as BarChartDataset).labels[
                      dataIndex
                    ];
                    return label;
                  }
                : function () {
                    return "";
                  },
              font: { size: 14 },
              padding: {
                bottom: 50,
              },
            },
            value: {
              anchor: "end",
              align: "top",
              formatter: lifeCycle
                ? function (value, _) {
                    if (value != null) {
                      const round = (value: number) => value.toFixed(0);

                      let stringValue = round(value);
                      if (stringValue === round(0)) {
                        stringValue = "<1";
                      }

                      return stringValue + "%";
                    }
                  }
                : function () {
                    return "";
                  },
              font: { size: 16, weight: "bold" },
            },
          },
        },
        tooltip: {
          position:
            externalTooltipHandler === undefined ? "nearest" : undefined,
          external: externalTooltipHandler ?? undefined,
          enabled: tooltipsEnabled && externalTooltipHandler === undefined,
          mode: "nearest",
          displayColors: false,
          bodyFont: {
            size: 13,
            style: "normal",
          },
          bodyAlign: "center",
          backgroundColor: infoCol,
          bodyColor: lightCol,
          callbacks: {
            title() {
              return "";
            },

            label(tooltipItem) {
              return (tooltipItem.dataset as BarChartDataset).labels[
                tooltipItem.dataIndex
              ].toLocaleString();
            },
            footer(tooltipItem) {
              const item = tooltipItem[0].dataset;
              const itemIndex = tooltipItem[0].dataIndex;
              const toRoundedString = (data: number, decimalPlaces: number) => {
                return data.toFixed(decimalPlaces).toLocaleString();
              };
              return [
                toRoundedString(item.data[itemIndex], effectType.decimals),
                effectType.unitString(intl),
              ];
            },
          },
        },
      },
      layout: {
        padding: {
          top: lifeCycle ? 50 : 30,
        },
      },
      scales: {
        x: {
          position: lifeCycle ? { y: 0 } : undefined,
          grid: lifeCycle
            ? {
                display: true,
                drawBorder: true,
                color: foodstepsTurquoiseCol,
                borderColor: foodstepsTurquoiseCol,
                lineWidth: 2,
                borderWidth: 2,
                drawOnChartArea: false,
              }
            : {
                display: false,
                drawBorder: false,
              },
          ticks: {
            display: !lifeCycle,
            maxRotation: 25,
            minRotation: 25,
            font: { size: 12, weight: "normal", family: "Graphik Web" },
          },
        },
        y: {
          beginAtZero: true,
          display: !lifeCycle,
          title: {
            display: !lifeCycle,
            text: `${effectType.title(intl)} (${effectType.unitString(intl)})`,
            font: { size: 13, weight: "normal", family: "Graphik Web" },
          },
          ticks: {
            display: true,
            font: { size: 13, weight: "normal", family: "Graphik Web" },
          },
          grid: {
            drawBorder: true,
            drawOnChartArea: false,
          },
        },
      },
    },
  };
};

export default function BarChart(props: BarChartProps) {
  const {
    chartData,
    effectType,
    externalTooltipHandler,
    tooltipsEnabled,
    lifeCycle,
  } = props;
  const intl = useIntl();
  const chartConfig = useMemoOne(
    () =>
      createChartConfig(intl, {
        chartData,
        effectType,
        externalTooltipHandler,
        tooltipsEnabled,
        lifeCycle,
      }),
    [
      chartData,
      effectType,
      externalTooltipHandler,
      intl,
      tooltipsEnabled,
      lifeCycle,
    ]
  );
  // ts-ignore
  return <Chart chartConfig={chartConfig} />;
}
