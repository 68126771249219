import classNames from "classnames";

import {
  ImpactRating,
  impactRatingToLongName,
  isImpactRating,
} from "../../domain/impactRatings";

import "./ImpactRatingDisplay.css";

export function impactRatingToCssClass(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "impact-rating-display__very-low";
    case "LOW":
      return "impact-rating-display__low";
    case "MEDIUM":
      return "impact-rating-display__medium";
    case "HIGH":
      return "impact-rating-display__high";
    case "VHIGH":
      return "impact-rating-display__very-high";
  }
}

interface ImpactRatingDisplayProps {
  impactRating: string;
}

export default function ImpactRatingDisplay(props: ImpactRatingDisplayProps) {
  const { impactRating } = props;

  if (isImpactRating(impactRating)) {
    return (
      <div
        className={classNames(
          "impact-rating-display",
          impactRatingToCssClass(impactRating)
        )}
      >
        {impactRatingToLongName(impactRating)}
      </div>
    );
  } else {
    return null;
  }
}

// in the ingredient breakdown chart model we can't access our feature flag hook
// because it's not wrapped in the same context providers
export function ImpactRatingDisplayTooltipHack(
  props: ImpactRatingDisplayProps
) {
  const { impactRating } = props;

  if (isImpactRating(impactRating)) {
    return (
      <div
        className={classNames(
          "impact-rating-display",
          impactRatingToCssClass(impactRating)
        )}
      >
        {impactRatingToLongName(impactRating)}
      </div>
    );
  } else {
    return null;
  }
}
