import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { SecondaryButton } from "../utils/Button";
import { Generate } from "../utils/Vectors";
import CarbonLabel from "./carbon-label/CarbonLabel";
import DownloadLabelsModal from "./DownloadLabelsModal";
import { effectType, impactMagnitude } from "./helperFunctions";
import {
  RecipePageCarbonLabel_Recipe as Recipe,
  RecipePageCarbonLabel_RecipeImpact as Impact,
} from "./RecipePageCarbonLabel.graphql";

interface RecipePageCarbonLabelProps {
  impact: Impact;
  recipe: Recipe;
  showExportButton: boolean;
}

export default function RecipePageCarbonLabel(
  props: RecipePageCarbonLabelProps
) {
  const { impact, recipe, showExportButton } = props;
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  return (
    <div className="d-flex flex-column align-items-center">
      <CarbonLabel
        className="mb-4"
        type="descriptiveImpactAndScale"
        impactRating={impact.impactRating!}
        impactMagnitude={impactMagnitude(recipe.isHotDrink, impact.effects)}
        effectType={effectType(recipe.isHotDrink)}
        width={100}
      />
      {showDownloadModal && (
        <DownloadLabelsModal
          onHide={() => setShowDownloadModal(false)}
          page="Recipe"
          recipeFilter={{ ids: [recipe.id] }}
          show={showDownloadModal}
        />
      )}
      {showExportButton && (
        <SecondaryButton
          icon={<Generate className="mb-1" width={20} />}
          onClick={() => setShowDownloadModal(true)}
        >
          <FormattedMessage
            id="components/labels/CarbonLabelCard:export"
            defaultMessage="Export"
          />
        </SecondaryButton>
      )}
    </div>
  );
}

RecipePageCarbonLabel.fragments = {
  recipeImpact: gql`
    fragment RecipePageCarbonLabel_RecipeImpact on RecipeImpact {
      effects {
        ghgPerRootRecipeServing
        ghgPerKg
      }
      impactRating
    }
  `,

  recipe: gql`
    fragment RecipePageCarbonLabel_Recipe on Recipe {
      id
      isHotDrink
      name
    }
  `,
};
